// @components/document/DocumentDetailsPrompt.component.tsx

import React, { useState } from "react";
import { View, Text, TextInput, Button, ScrollView, TouchableOpacity } from "react-native";
import { Markdown as MarkdownType } from "@model/markdown";
import { DocumentLevelPromptStyles as styles } from "@styles/DocumentLevelPrompt.style";
import Markdown from "react-native-markdown-display";
import * as T from "fp-ts/lib/Task";
import { pipe } from "fp-ts/lib/function";
import { useTaskState } from "@utils/useTaskState";

type DocumentDetailsPromptProps = {
  documentQuestion: MarkdownType;
  onSubmitTask: (userInput: string) => T.Task<void>; // Task-based onSubmit
};

const DocumentDetailsPromptComponent: React.FC<DocumentDetailsPromptProps> = ({
  documentQuestion,
  onSubmitTask,
}) => {
  const [userInput, setUserInputImpure] = useState<string>(""); //replace impure with io

  const handleOnSubmit = () => {
    void pipe(
      onSubmitTask(userInput),
      T.chain(() => T.fromIO(() => setUserInputImpure(""))) // Clear the input field after submission
    )();
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Document Level Question</Text>
      <ScrollView style={styles.documentQuestionContainer}>
        <Markdown>{documentQuestion}</Markdown>
      </ScrollView>
      <TextInput
        style={styles.input}
        value={userInput}
        onChangeText={setUserInputImpure}
        placeholderTextColor={'gray'}
        placeholder="You can also provide additional context or examples in addition to responding to the questions."
        multiline={true}
      />
      <TouchableOpacity
        style={styles.button2}
        onPress={handleOnSubmit} 
      >
        <Text style={styles.buttonText}>Submit</Text>
      </TouchableOpacity>
    </View>
  );
};

export default DocumentDetailsPromptComponent;
