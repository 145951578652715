import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Dimensions } from 'react-native';
import { TextInput, Button, Card, Title } from 'react-native-paper';
import { gradientColors } from '@styles/Fancy.style';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import {Email} from "@model/clients/AuthenticationApi";
import {Task} from "fp-ts/Task";
import {useTaskState} from "@utils/useTaskState";

interface ForgotPasswordPageProps {
  onForgotPasswordSubmit: (email: Email) => Task<void>
}

const CreateForgotPasswordComponent: React.FC<ForgotPasswordPageProps> = ({ onForgotPasswordSubmit }) => {
  const [email, setEmailTask] = useTaskState<string>('');

  const handleResetPassword = () => {
    console.log('Reset password for:', email);
    onForgotPasswordSubmit(Email(email));
  };

  return (
    <LinearGradient
      colors={gradientColors}
      style={sexyStyles.background}
    >
      <View style={sexyStyles.container}>
        <Card style={sexyStyles.card}>
          <Card.Content>
            <Title style={sexyStyles.title}>Forgot Password</Title>
            <TextInput
              label="Email"
              value={email}
              onChangeText={text => setEmailTask(text)()}
              style={sexyStyles.input}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <Button mode="contained" onPress={handleResetPassword} style={sexyStyles.button}>
              Reset Password
            </Button>
          </Card.Content>
        </Card>
      </View>
    </LinearGradient>
  );
};

export default CreateForgotPasswordComponent;
