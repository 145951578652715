// @components/FinalEdits.tsx
import React from "react";
import { View, Text, TextInput, Button, TouchableOpacity } from "react-native";
import { FinalEditsStyles as styles } from "@styles/FinalEdits.style";
import * as T from "fp-ts/lib/Task";
import { IO } from "fp-ts/lib/IO";

type FinalEditsComponentProps = {
  finalEdits: string;
  setFinalEdits: (text: string) => IO<void>; // Updated to use IO
  onSubmitTask: T.Task<void>;
};

const FinalEditsComponent: React.FC<FinalEditsComponentProps> = ({
  finalEdits,
  setFinalEdits,
  onSubmitTask,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Manual Edits</Text>
      <TextInput
        style={styles.input}
        value={finalEdits}
        onChangeText={(text) => setFinalEdits(text)()} // Now using IO, triggering it immediately
        placeholder="Enter your edits here, the AI will be notified about your changes."
        multiline
      />
      <TouchableOpacity
        style={styles.button2}
        onPress={() => void onSubmitTask()}
      >
        <Text style={styles.buttonText}>Submit Edits</Text>
      </TouchableOpacity>
    </View>
  );
};

export default FinalEditsComponent;
