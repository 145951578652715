// Helper function to simulate delay (using TaskEither)
import {TaskEither} from "fp-ts/lib/TaskEither";

export function delayTaskEither<E, A>(
    ms: number,
    taskEither: TaskEither<E, A>
): TaskEither<E, A> {
    return async () => {
        await new Promise((resolve) => setTimeout(resolve, ms));
        return taskEither();
    };
}
