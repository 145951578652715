// @navigation/navigators/Authentication.navigator.tsx
import React from "react";
import {
    createStackNavigator,
    StackNavigationProp,
} from "@react-navigation/stack";
import {
    AuthenticationStackParamList,
    AppStackParamList,
} from "@navigation/NavigationRouteParameters";
import LoginPage from "@pages/account/Login.page";
import CreateAccountPage from "@pages/account/CreateAccount.page";
import { AppRoutes, AuthenticationRoutes } from "@navigation/RouteEnums";
import ForgotPasswordPage from "@pages/account/ForgotPassword.page";
import withAppNavigator from "@navigation/helpers/withAppNavigator";

const Stack = createStackNavigator<AuthenticationStackParamList>();

type AuthenticationNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const AuthenticationNavigator: React.FC<AuthenticationNavigatorProps> = ({
                                                                             navigation,
                                                                         }) => {
    return (
        <Stack.Navigator
            initialRouteName={AuthenticationRoutes.LOGIN}
            screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                detachPreviousScreen: false,
            }}
        >
            <Stack.Screen
                name={AuthenticationRoutes.LOGIN}
                component={withAppNavigator(LoginPage, navigation)}
            />

            <Stack.Screen
                name={AuthenticationRoutes.CREATE_ACCOUNT}
                component={withAppNavigator(CreateAccountPage, navigation)}
            />

            <Stack.Screen
                name={AuthenticationRoutes.FORGOT_PASSWORD}
                component={ForgotPasswordPage}
            />
        </Stack.Navigator>
    );
};

export default AuthenticationNavigator;
