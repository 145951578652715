// @components/workspace/AccountManagement.tsx

import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { AccountResponse } from '@model/clients/AccountApi';
import { Task } from 'fp-ts/lib/Task';
import * as Option from 'fp-ts/lib/Option';

export type AccountManagementComponentProps = {
    accountDetails: Option.Option<AccountResponse>;
    onCancelSubscription: Task<void>;
    onLogout: Task<void>;
};

const AccountManagementComponent: React.FC<AccountManagementComponentProps> = ({
                                                                                   accountDetails,
                                                                                   onCancelSubscription,
                                                                                   onLogout,
                                                                               }) => {
    const renderAccountDetails: (ma: Option.Option<AccountResponse>) => React.JSX.Element = Option.fold(
        () => <Text style={styles.loading}>Loading account details...</Text>,
        (details: AccountResponse) => (
            <>
                <View style={styles.detailsContainer}>
                    <Text style={styles.label}>Email:</Text>
                    <Text style={styles.value}>{details.email}</Text>
                    <Text style={styles.label}>Subscription Status:</Text>
                    <Text style={styles.value}>{details.status}</Text>
                    <Text style={styles.label}>Expiration Date:</Text>
                    <Text style={styles.value}>
                        {details.expiration.toLocaleDateString()}
                    </Text>
                </View>

                <View style={styles.actionsContainer}>
                    <TouchableOpacity
                        style={styles.actionButton}
                        onPress={onCancelSubscription}
                    >
                        <MaterialIcons name="cancel" size={24} color="#fff" />
                        <Text style={styles.actionButtonText}>Cancel Subscription</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.actionButton} onPress={onLogout}>
                        <MaterialIcons name="logout" size={24} color="#fff" />
                        <Text style={styles.actionButtonText}>Logout</Text>
                    </TouchableOpacity>
                </View>
            </>
        )
    );

    return <View style={styles.container}>{renderAccountDetails(accountDetails)}</View>;
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    detailsContainer: {
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#fff',
        color: 'gray',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        minHeight: 100,
        textAlignVertical: 'top',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
    },
    value: {
        fontSize: 16,
        marginBottom: 10,
    },
    loading: {
        fontSize: 18,
        fontStyle: 'italic',
        color: '#666',
        textAlign: 'center',
        marginTop: 50,
    },
    actionsContainer: {
        marginTop: 20,
    },
    actionButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#296a6a',
        padding: 15,
        borderRadius: 5,
        marginTop: 10,
    },
    actionButtonText: {
        color: '#fff',
        fontSize: 16,
        marginLeft: 10,
    },
});

export default AccountManagementComponent;
