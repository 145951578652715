// @pages/workspace/Home.page.tsx

import React, {useCallback, useMemo} from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { WorkspaceRoutes } from "@navigation/RouteEnums";
import { WorkspaceRoutesParamList } from "@navigation/NavigationRouteParameters";
import HomeComponent from "@components/workspace/Home";
import * as IO from "fp-ts/lib/IO";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // For IO-based loading state
import { pipe } from "fp-ts/lib/function";

type HomeProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.HOME>;
};

const HomePage: React.FC<HomeProps> = ({ navigation }) => {
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();

    // Define the IO-based navigation action to the Document Creation page
    const navigateToDocumentCreation: IO.IO<void> = useCallback(
        pipe(
            triggerLoadingState,
            IO.flatMap(() => IO.of(navigation.navigate(WorkspaceRoutes.DOCUMENT_CREATION))),
            IO.flatMap(() => triggerSuccessState) // Trigger success after navigation
        ), [triggerLoadingState, triggerSuccessState]
    )

    return (
        <HomeComponent navigateToDocumentCreation={navigateToDocumentCreation} />
    );
};

export default HomePage;
