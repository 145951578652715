// @services/ouroboros/DefaultLifecycleClient.ts

import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DocumentID } from '@model/documents/core';
import {
    CreateDocumentRequest,
    CreateDocumentResponse,
    ListAvailableDocumentsResponse,
    DocumentFetchResponse,
    OutlineResponse,
    LifecycleError,
} from '@model/clients/LifecycleApi';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import {TaskEither, tryCatch, left, chain, right} from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

export class DefaultLifecycleClient implements LifecycleClient {
    constructor() {
        // No dependencies
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private fetchWithTrace(
        url: string,
        options: RequestInit
    ): TaskEither<LifecycleError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };

        options.credentials = 'include';

        return tryCatch(
            async () => {
                const response = await fetch(url, options);

                if (!response.ok) {
                    throw {
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError;
                }

                return response;
            },
            (e) => {
                if (e instanceof TypeError) {
                    return {
                        type: 'NetworkError',
                        message: 'Failed to connect to the server. Please check your internet connection.',
                    } as LifecycleError;
                }
                return e as LifecycleError;
            }
        );
    }

    private validateResponse<T>(
        response: Response,
        validator: t.Type<T>
    ): TaskEither<LifecycleError, T> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = validator.decode(data);
                if (decoded._tag === 'Right') {
                    return decoded.right;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation failed',
                } as LifecycleError)
        );
    }

    createDocument(
        request: CreateDocumentRequest
    ): TaskEither<LifecycleError, CreateDocumentResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<CreateDocumentResponse>(
                        response,
                        CreateDocumentResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }

    fetchOutline(documentId: DocumentID): TaskEither<LifecycleError, OutlineResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/outline`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<OutlineResponse>(response, OutlineResponse)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }

    fetchDocument(documentId: DocumentID): TaskEither<LifecycleError, DocumentFetchResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<DocumentFetchResponse>(response, DocumentFetchResponse)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }

    listAvailableDocuments(): TaskEither<LifecycleError, ListAvailableDocumentsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<ListAvailableDocumentsResponse>(
                        response,
                        ListAvailableDocumentsResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }

    // New Method: downloadDocumentAsBlob
    downloadDocumentAsBlob(documentId: DocumentID): TaskEither<LifecycleError, Blob> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/export/markdown`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
            }),
            chain((response) =>
                response.ok
                    ? tryCatch(
                        async () => {
                            // Convert the response into a Blob
                            return await response.blob();
                        },
                        (e) => ({
                            type: 'DownloadError',
                            message: `Failed to download document: ${e}`,
                        } as LifecycleError)
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }

    // New Method: deleteDocument
    deleteDocument(documentId: DocumentID): TaskEither<LifecycleError, void> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/${documentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            chain((response) =>
                response.ok
                    ? right(void 0) // Return void on successful deletion
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as LifecycleError)
            )
        );
    }
}
