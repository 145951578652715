// @styles/Prompt.style.ts
import { StyleSheet } from 'react-native';

export const PromptStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    button2: {
        paddingVertical: 5.5,
        paddingHorizontal: 20,
        backgroundColor: '#296a6a',
        borderRadius: 8,
        marginLeft: 2
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    probingQuestionContainer: {
        marginBottom: 16,
        padding: 10,
        backgroundColor: '#fff',
        color: 'gray',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        minHeight: 100,
        textAlignVertical: 'top',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },
    probingQuestion: {
        fontSize: 16,
        lineHeight: 24,
    },
    input: {
        width: '100%',
        borderColor: '#ccc',
        color: 'black',
        borderWidth: 1,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
        textAlignVertical: 'center', // Ensure multiline text is top-aligned
        backgroundColor: '#f9f9f9', // Slight background color for contrast
    },
});

export default PromptStyles;
