// @styles/DocumentCreation.style
import { StyleSheet } from 'react-native';

export const DocumentCreationStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    button2: {
        paddingVertical: 5,
        paddingHorizontal: 20,
        paddingBottom: 10,
        backgroundColor: '#296a6a',
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    input: {
        height: 40,
        borderColor: '#ddd',
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 5,
        color: 'black',
        marginBottom: 16,
        padding: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },

    multiline: {
        height: 200,
        backgroundColor: '#fff',
        borderColor: '#ddd',
        borderWidth: 1,
        color: 'black',
        borderRadius: 5,
        marginBottom: 16,
        padding: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },
});
