// App.navigator.tsx
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppStackParamList } from '@navigation/NavigationRouteParameters';
import AuthenticationNavigator from '@navigation/navigator/Authentication.navigator';
import WorkspaceNavigator from '@navigation/navigator/Workspace.navigator';
import DocumentNavigator from '@navigation/navigator/Document.navigator';
import { AppRoutes } from '@navigation/RouteEnums';
import PaymentNavigator from "@navigation/navigator/Payment.navigator";

const Stack = createStackNavigator<AppStackParamList>();

const AppNavigator: React.FC = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name={AppRoutes.AUTH} component={AuthenticationNavigator} options={{headerShown: false}} />
            <Stack.Screen name={AppRoutes.WORKSPACE} component={WorkspaceNavigator} options={{headerShown: false}} />
            <Stack.Screen name={AppRoutes.DOCUMENT} component={DocumentNavigator} options={{headerShown: false}}/>
            <Stack.Screen name={AppRoutes.PAYMENT} component={PaymentNavigator} options={{headerShown: false}} />
        </Stack.Navigator>
    );
};

export default AppNavigator;