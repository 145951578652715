// @utils/cardSetupErrorHandler.ts

import { CardSetupError } from '@model/payments';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities

export const handleCardSetupErrorIo = (
    error: CardSetupError,
    setError: (message: string) => IO.IO<void>
): IO.IO<void> => {
    switch (error.type) {
        case 'InitializationError':
            return setError("Stripe didn't load correctly, refresh the page or try again later.");
        case 'StripeConnectionError':
            return setError("Your payment was submitted but couldn't be confirmed, please try again later.");
        case 'AddressElementError':
            return setError('There was an issue parsing your billing information, please review it or try again later.');
        case 'CardElementError':
            return setError('There was an issue parsing your card information, please review it or try again later.');
        default:
            console.error(error);
            return setError('An unknown error occurred. Please try again.');
    }
};
