import React from "react";
import { View, StyleSheet } from "react-native";
import {
  Button,
  RadioButton,
  Text,
  Title,
  Paragraph,
  Card,
  TextInput,
} from "react-native-paper";
import { SubscriptionPlan } from "@model/clients/PaymentApi";
import * as T from "fp-ts/lib/Task";
import { sexyStyles } from "@styles/OuroborosSexyTheme.style";
import { SubscriptionStatus } from "@model/clients/AuthenticationApi";
import { useIoState } from "@utils/useIoState"; 

type ChoosePackageComponentProps = {
  onPackageSelectionTask: (packageType: SubscriptionPlan) => T.Task<void>;
  subscriptionStatus: SubscriptionStatus;
  setPromoCode: (code: string) => T.Task<void>;
};

const ChooseSubscriptionComponent: React.FC<ChoosePackageComponentProps> = ({
  onPackageSelectionTask,
  subscriptionStatus,
  setPromoCode,
}) => {
  const [selectedPackage, setSelectedPackage] = useIoState<SubscriptionPlan>("Monthly");
  const [loading, setLoading] = React.useState(false);
  const [loadingPromo, setLoadingPromo] = React.useState(false);
  const [promoCode, setPromoCodeState] = React.useState<string>("");
  const [promoCodeError, setPromoCodeError] = React.useState<string | null>(null);
  const [promoCodeSuccess, setPromoCodeSuccessState] = React.useState<boolean>(false);

  const helperText = (() => {
    switch (subscriptionStatus) {
      case "PaymentFailed":
        return "There was an issue processing your previous payment, please update your information.";
      case "PaymentNotYetConfigured":
        return "All packages come with a 30-day free trial, and there's no charge for canceling before the trial expires.";
      case "FreeTrialMode":
        return "You are currently in free trial mode. Upgrade to a paid plan before the trial expires.";
      case "Paid":
        return "Thank you for being a valued subscriber! You can update your plan here.";
      case "Canceled":
        return "Your previous subscription was canceled, but you can restart it by purchasing a plan below.";
    }
  })();

  const handlePackageSelection = async () => {
    setLoading(true);
    await onPackageSelectionTask(selectedPackage)();
    setLoading(false);
  };

  const handlePromoCodeSubmit = async () => {
    if (!promoCode) {
      setPromoCodeError("Please enter a valid promo code.");
      return;
    }
    setPromoCodeError(null);
    await setPromoCode(promoCode)();
  };

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        <Title style={styles.title}>Select a Subscription Package</Title>
        <Paragraph>{helperText}</Paragraph>

        <RadioButton.Group
          onValueChange={(value: string) => setSelectedPackage(value as SubscriptionPlan)()}
          value={selectedPackage ?? ""}
        >
          <View style={styles.mainContainerStyle}>
          {["Monthly", "Annual"].map((plan) => (
            <View key={plan} style={styles.radioButtonContainer}>
              <RadioButton.Item
                label={plan}
                value={plan}
                status={selectedPackage === plan ? "checked" : "unchecked"}
                color="#296a6a"
                style={selectedPackage === plan ? styles.selectedRadioButton : styles.unSelectedRadioButton}
              />
              <Paragraph style={styles.planDetails}>
                {plan === "Monthly" ? "Subscribe monthly for more flexibility. $50/month" : "Pay annually and get 2 months free! $500/year"}
              </Paragraph>
            </View>
          ))}
          </View>
        </RadioButton.Group>
        <Text style={styles.textHeading}>If you have a promo code, please enter it below</Text>
        {/* Promo Code Input */}
        <TextInput
          label="Enter Promo Code"
          value={promoCode}
          onChangeText={setPromoCodeState}
          style={styles.promoInput}
          error={!!promoCodeError}
        />
        {promoCodeError && <Text style={styles.errorText}>{promoCodeError}</Text>}
        {promoCodeSuccess && <Text style={styles.successText}>Promo Code Applied Successfully!</Text>}
        
        <Button
          mode="contained"
          onPress={handlePromoCodeSubmit}
          disabled={loading || loadingPromo}
          style={styles.button}
        >
          {loadingPromo ? "Applying..." : "Apply Promo Code"}
        </Button>

        {/* Continue to Payment Button */}
        <Button
          mode="contained"
          onPress={handlePackageSelection}
          disabled={!selectedPackage || loading}
          style={styles.button}
        >
          {loading ? "Processing..." : "Continue to Payment"}
        </Button>

        {selectedPackage && (
            <Text style={styles.selectedPackageText}>
              You’ve selected the {selectedPackage} package. All plans include a 30-day free trial, during which you can cancel without charge.
            </Text>
        )}
        <Text style={styles.selectedPackageText}>
          By proceeding, you authorize billing to begin at the end of the trial period. You may cancel anytime through your account settings to avoid future charges.
        </Text>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: 16,
  },
  mainContainerStyle: {
    marginTop: 5,
    borderColor: '#d3d3d3',
    borderWidth: 1,
    borderRadius: 8,
    padding: 10,
    backgroundColor: '#f8f8f8',
  },
  card: {
    padding: 16,
    borderRadius: 8,
    elevation: 3,
  },
  title: {
    marginBottom: 8,
  },
  textHeading: {
    marginTop: 20,
    marginBottom: 8,
    paddingLeft: 5
  },
  radioButtonContainer: {
    marginVertical: 8,
    paddingVertical: 8, 
    backgroundColor: "#ffffff",
    borderRadius: 8,
    paddingHorizontal: 8,
    marginBottom: 8, 
    borderWidth: 1,
    borderColor: '#ddd',
  },
  selectedRadioButton: {
    backgroundColor: "#e7e0ec", 
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
  unSelectedRadioButton: {
    backgroundColor: "#f5f5f5", 
    borderColor: "#ececec", 
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
  planDetails: {
    marginLeft: 32,
    marginTop: 8, 
    color: "#666", 
  },
  button: {
    marginTop: 16,
    backgroundColor: "#296a6a",
  },
  selectedPackageText: {
    marginTop: 16,
    color: "#333",
  },
  promoInput: {
    marginBottom: 8,
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
  successText: {
    color: "green",
    fontSize: 12,
    marginTop: 4,
  }
});

export default ChooseSubscriptionComponent;
