// @components/writing/review/SectionList.tsx
import React from "react";
import { FlatList, Text } from "react-native";
import { SectionID } from "@model/documents/core";
import { useNonNullDocumentContext } from "@contexts/Document.context";
import Section from "./Section";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import { UserSectionReplacement } from "@model/clients/ContentApi"; // Import Task utilities

type SectionListProps = {
  handleRefine: IO.IO<void>;
  handleEdits: IO.IO<void>;
  handleDraft: IO.IO<void>;
};

const SectionList: React.FC<SectionListProps> = ({
  handleRefine,
  handleEdits,
  handleDraft,
}) => {
  const { document, activeSectionID, setActiveSectionIDIo } =
    useNonNullDocumentContext();

  // Sort sections by metadata.index
  const sortedSections = [...document.document_contents].sort(
    (a, b) => a.metadata.index - b.metadata.index
  );

  return (
    <>
      <Text style={{ paddingHorizontal: 16, paddingTop: 16, color: '#000', backgroundColor: '#fff' }}>
        <span style={{fontWeight: 'bold'}}>Note:-</span> Click to see options
      </Text>
      <FlatList
        style={{ padding: 10, backgroundColor: '#fff' }}
        data={sortedSections} // Use sorted data
        renderItem={({ item }) => (
          <Section
            section={item}
            active={activeSectionID === item.metadata.id}
            handleRefine={handleRefine}
            handleFinalEdits={handleEdits}
            handleDraft={handleDraft}
            setActiveSection={setActiveSectionIDIo} // Task-based setter
          />
        )}
        keyExtractor={(item) => item.metadata.id}
        extraData={activeSectionID} // Rerender only when active section changes
      />
    </>
  );
};

export default SectionList;
