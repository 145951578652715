// utils/enabledFeatures.ts

export enum FeaturesEnum {
    SignInWithGoogle,
    ForgotPassword,
    SexyDocuments,
    SexySpinner,
    DocumentSearching,
    DemoDocumentData,
    StubClients,
    DrawerSignOutButton
}

export const enabledFeatures: { [key in FeaturesEnum]: boolean } = {
    [FeaturesEnum.SignInWithGoogle]: false,
    [FeaturesEnum.ForgotPassword]: false,
    [FeaturesEnum.SexyDocuments]: false,
    [FeaturesEnum.SexySpinner]: true,
    [FeaturesEnum.DocumentSearching]: false,
    [FeaturesEnum.DemoDocumentData]: false,
    [FeaturesEnum.StubClients]: process.env.STUB_CLIENTS === "1",
    [FeaturesEnum.DrawerSignOutButton]: true
}