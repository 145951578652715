// @utils/lifecycleErrorHandler.ts

import { LifecycleError } from '@model/clients/LifecycleApi';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities

export const handleLifecycleErrorIo = (
    error: LifecycleError,
    setError: (message: string) => IO.IO<void>
): IO.IO<void> => {
    switch (error.type) {
        case 'ValidationError':
            return setError('The response from the server could not be read, please update your app or refresh your web browser. If the problem persists, contact support.');
        case 'NetworkError':
            return setError('Network error. Please check your internet connection.');
        case 'DownloadError':
            return setError(`There was an error downloading your document, please try again later. Details: ${error.message}`);
        case 'ServerError':
            return setError(`Server error: ${error.message} (Status code: ${error.statusCode})`);
        case 'UnknownError':
        default:
            console.error(error);
            return setError('An unknown error occurred. Please try again.');
    }
};
