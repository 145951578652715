// @utils/brandedDecoder.ts
import * as t from 'io-ts';
import { Either, isRight } from 'fp-ts/Either'; // Needed for handling Either types

// Utility to create branded decoders
export function createBrandedDecoder<A extends O, O = A, I = unknown>(
    type: t.Type<A, O, I>, // Original type to validate
    isBrand: (u: A) => boolean, // Function to check if the type can be branded
    brandName: string // Name of the brand (for better error messages)
): t.Type<A, O, I> {
    return new t.Type<A, O, I>(
        brandName, // Brand name for the type
        (input: unknown): input is A => type.is(input) && isBrand(input), // Check if the input is of the right type and can be branded
        (input, context): Either<t.Errors, A> => {
            const result = type.validate(input, context); // Validate the input using the original type
            return isRight(result) && isBrand(result.right)
                ? t.success(result.right)
                : t.failure(input, context);
        },
        (a: A): O => a // Encode: since A extends O, we can pass through the value
    );
}
