// @components/workspaces/LoginPage.tsx

import React, { useEffect, useCallback } from "react"; // Removed useState as we're using useIoState
import { View, StyleSheet, Image, Dimensions, Text } from "react-native";
import { TextInput, Button, Card, Title, Paragraph } from "react-native-paper";
import {
  GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from "@react-native-google-signin/google-signin";
import { LinearGradient } from "expo-linear-gradient";
import { gradientColors } from "@styles/Fancy.style";
import { useAssets } from "expo-asset";
import { enabledFeatures, FeaturesEnum } from "@src/utils/enabledFeatures";
import { AuthenticationRoutes } from "@navigation/RouteEnums";
import {
  NavigationContainerRefWithCurrent,
  useFocusEffect,
  useNavigation,
} from "@react-navigation/native";
import { AuthenticationStackParamList } from "@navigation/NavigationRouteParameters";
import { sexyStyles } from "@src/styles/OuroborosSexyTheme.style";
import { Email, Password } from "@model/clients/AuthenticationApi";
import * as T from "fp-ts/lib/Task"; // Task utilities remain for task-based logic
import * as TE from "fp-ts/lib/TaskEither"; // Import TaskEither utilities
import * as IO from "fp-ts/lib/IO"; // Import TaskEither utilities
import { pipe } from "fp-ts/lib/function";
import { useIoState } from "@utils/useIoState"; // Import IoState for managing state

const { width, height } = Dimensions.get("window");

export type LoginComponentProps = {
  onLogin: (email: Email, password: Password) => T.Task<void>;
  navigateToCreateAccount: IO.IO<void>;
};

const LoginPage: React.FC<LoginComponentProps> = ({ onLogin }) => {
  const [email, setEmail] = useIoState<string>("");
  const [password, setPassword] = useIoState<string>("");
  const [assets, error] = useAssets([
    require("@public/ouroboros_logo_flat.png"),
  ]);

  const navigation: NavigationContainerRefWithCurrent<AuthenticationStackParamList> =
    useNavigation();

  useEffect(() => {
    if (enabledFeatures[FeaturesEnum.SignInWithGoogle]) {
      void configureGoogleSignInTask(); // Execute the task
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter" && email && password) {
          void handleLoginTask(); // Trigger login task when Enter is pressed and fields are populated
        }
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [email, password])
  );

  // Task to configure Google Sign-In
  const configureGoogleSignInTask: T.Task<void> = T.fromIO(() => {
    GoogleSignin.configure({
      webClientId: "YOUR_WEB_CLIENT_ID", // From Google Cloud Console
      offlineAccess: false,
    });
  });

  // Task to handle user login
  const handleLoginTask: T.Task<void> = onLogin(
    Email(email),
    Password(password)
  ); // Trigger login task

  // TaskEither to handle Google Sign-In with error handling
  const signInWithGoogleTask = (): TE.TaskEither<Error, void> =>
    TE.tryCatch(
      async () => {
        await GoogleSignin.hasPlayServices();
        const userInfo = await GoogleSignin.signIn();
        console.log("User Info:", userInfo);
      },
      (error: any) => {
        if (error.code === statusCodes.SIGN_IN_CANCELLED) {
          console.log("User cancelled the login flow");
        } else if (error.code === statusCodes.IN_PROGRESS) {
          console.log("Operation (e.g., sign in) is in progress already");
        } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
          console.log("Play services not available or outdated");
        } else {
          console.error("Some other error happened:", error);
        }
        return new Error("Google Sign-In failed");
      }
    );

  const logo = assets ? (
    <Image style={styles.logoStyle} source={{ uri: assets[0].uri }} />
  ) : null;

  return (
    <LinearGradient colors={gradientColors} style={sexyStyles.background}>
      <View style={sexyStyles.container}>
        <Card style={sexyStyles.card}>
          <Card.Content>
            {logo}
            <Title style={sexyStyles.title}>Ouroboros Docs Login</Title>
            <TextInput
              label="Email"
              value={email}
              onChangeText={(text) => setEmail(text)()} // Trigger IO-based setter
              style={sexyStyles.input}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <TextInput
              label="Password"
              value={password}
              onChangeText={(text) => setPassword(text)()} // Trigger IO-based setter
              style={sexyStyles.input}
              secureTextEntry
            />
            <Button
              mode="contained"
              onPress={() => handleLoginTask()}
              style={sexyStyles.button}
            >
              Login
            </Button>
            {enabledFeatures[FeaturesEnum.ForgotPassword] && (
              <Button
                mode="text"
                onPress={() =>
                  navigation.navigate(AuthenticationRoutes.FORGOT_PASSWORD)
                }
                style={sexyStyles.textButton}
              >
                <Text style={{color: '#000'}}>
                  Forgot Password?
                </Text>
              </Button>
            )}
            {enabledFeatures[FeaturesEnum.SignInWithGoogle] && (
              <GoogleSigninButton
                style={styles.googleButton}
                size={GoogleSigninButton.Size.Wide}
                color={GoogleSigninButton.Color.Dark}
                onPress={() => signInWithGoogleTask()()} // Execute TaskEither
              />
            )}
            <Paragraph style={styles.signUpText}>
              Don't have an account?
            </Paragraph>
            <Button
              mode="text"
              onPress={() =>
                navigation.navigate(AuthenticationRoutes.CREATE_ACCOUNT)
              }
              style={sexyStyles.textButton}
            >
              <Text style={{color: '#000'}}>Sign Up</Text>
            </Button>
          </Card.Content>
        </Card>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  logoStyle: {
    flex: 1,
    alignSelf: "center",
    height: 250,
    width: 250,
  },
  googleButton: {
    marginTop: 16,
  },
  signUpText: {
    marginTop: 16,
    textAlign: "center",
  },
});

export default LoginPage;
