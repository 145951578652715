// @components/workspace/DocumentCreation.tsx

import React from "react";
import { View, TextInput, Text, Button } from "react-native";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import * as T from "fp-ts/lib/Task"; // Task utilities remain for task-based logic
import { useIoState } from "@utils/useIoState"; // Use IoState for managing state with IO
import { TouchableOpacity } from "react-native-gesture-handler";

type DocumentCreationComponentProps = {
  onCreateDocumentTask: (
    title: string,
    style: string,
    outline: string
  ) => T.Task<void>; // Task-based function
};

const DocumentCreationComponent: React.FC<DocumentCreationComponentProps> = ({
  onCreateDocumentTask,
}) => {
  // Using io state instead of useState
  const [title, setTitle] = useIoState("");
  const [style, setStyle] = useIoState("");
  const [outline, setOutline] = useIoState("");

  const handleCreateDocument = (): void => {
    void onCreateDocumentTask(title, style, outline)(); // Execute the task when the button is pressed
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder="Title"
        value={title}
        placeholderTextColor={'gray'}
        onChangeText={(text) => setTitle(text)()} // Now using the IO-based setter
      />
      <TextInput
        style={styles.multiline}
        placeholder="Explain your intentions for the document and any style or tone guidelines. You can also provide examples or drafts."
        value={style}
        placeholderTextColor={'gray'}
        onChangeText={(text) => setStyle(text)()} // Now using the IO-based setter
        multiline={true}
      />
      <TextInput
        style={styles.multiline}
        placeholder="Please write your outline with one section title per line. You need at least one section. Avoid using bullets or numbering."
        value={outline}
        placeholderTextColor={'gray'}
        onChangeText={(text) => setOutline(text)()} // Now using the IO-based setter
        multiline={true}
      />
      <TouchableOpacity
        style={styles.button2}
        onPress={() => handleCreateDocument()}
      >
        <Text style={styles.buttonText}>Create Document</Text>
      </TouchableOpacity>
    </View>
  );
};

export default DocumentCreationComponent;
