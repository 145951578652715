// @navigation/RouteEnums.ts
export const AppRoutes = {
    AUTH: 'Authentication',
    WORKSPACE: 'Select Or Create Document',
    DOCUMENT: 'Writing',
    PAYMENT: 'Payment',  // New route for payment flow
} as const;

export const WorkspaceRoutes = {
    DOCUMENT_SELECTION: 'Document Selection',
    DOCUMENT_CREATION: 'Document Creation',
    FEEDBACK_SUBMISSION: 'Feedback Submission',
    ACCOUNT_MANAGEMENT: 'Account Management',
    HOME: 'Home',
} as const;

export const AuthenticationRoutes = {
    LOGIN: 'Login',
    CREATE_ACCOUNT: 'Create Account',
    FORGOT_PASSWORD: 'Forgot Password',
} as const;

export const PaymentRoutes = {
    CHOOSE_SUBSCRIPTION: 'Subscription Selection',
    PAYMENT_SETUP: 'Payment Setup',
    DEMOGRAPHIC_SURVEY: 'Demographic Survey',
} as const;

export const DocumentRoutes = {
    REVIEW: 'Review',
    FINAL_EDITS: 'Final Edits',
    PROMPT: 'Prompt',
    REFINE: 'Refine',
    DOCUMENT_LEVEL_PROMPT: 'Document Planning',
} as const;

export type AppRouteKeys = keyof typeof AppRoutes;
export type AccountRouteKeys = keyof typeof AuthenticationRoutes;
export type PaymentRouteKeys = keyof typeof PaymentRoutes;
export type DocumentRouteKeys = keyof typeof DocumentRoutes;
export type WorkspaceRouteKeys = keyof typeof WorkspaceRoutes;
export type AppRouteValues = typeof AppRoutes[AppRouteKeys];
export type AccountRouteValues = typeof AuthenticationRoutes[AccountRouteKeys];
export type PaymentRouteValues = typeof PaymentRoutes[PaymentRouteKeys];
export type DocumentRouteValues = typeof DocumentRoutes[DocumentRouteKeys];
export type WorkspaceRouteValues = typeof WorkspaceRoutes[WorkspaceRouteKeys];
