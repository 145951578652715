// @navigation/helpers/withAppNavigator.tsx
import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "@navigation/NavigationRouteParameters";

type WithAppNavigatorProps = {
    appNavigator: StackNavigationProp<AppStackParamList, keyof AppStackParamList>;
};

// This utility adds app navigator to the listed props
const withAppNavigator = <P extends WithAppNavigatorProps>(
    WrappedComponent: React.ComponentType<P>,
    appNavigator: StackNavigationProp<AppStackParamList, keyof AppStackParamList>
) => {
    return (props: Omit<P, "appNavigator">) => (
        <WrappedComponent {...(props as P)} appNavigator={appNavigator} />
    );
};

export default withAppNavigator;
