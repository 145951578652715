// @utils/useIoState.ts
import { useState, useCallback } from 'react';
import * as IO from 'fp-ts/IO';

export function useIoState<S>(initialState: S): [S, (s: S) => IO.IO<void>] {
    const [state, setState] = useState<S>(initialState);

    // Memoize the IO-based setter to avoid unnecessary re-renders
    const setIoState = useCallback((newState: S): IO.IO<void> => {
        return () => setState(newState);  // setState is effectful, and we're wrapping it in IO<void>
    }, []);

    return [state, setIoState];
}
