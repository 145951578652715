import React, { useCallback } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  AppRoutes,
  PaymentRoutes,
  WorkspaceRoutes,
} from "@navigation/RouteEnums";
import {
  AppStackParamList,
  PaymentStackParamList,
} from "@navigation/NavigationRouteParameters";
import {
  StripeSetupIntentSecret,
  SubscriptionPlan,
  PromoCodeResponse,
  SubscriptionStatus,
  PaymentError,
} from "@model/clients/PaymentApi";
import { usePaymentClient } from "@contexts/PaymentClient.context";
import { pipe } from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task"; // Import Task utilities
import * as TE from "fp-ts/lib/TaskEither"; // Import TaskEither utilities
import * as IO from "fp-ts/lib/IO"; // Import IO utilities
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // Use IO-based loading context setters
import { handlePaymentErrorIo } from "@utils/errorHandlers/paymentErrorHandler"; // Use IO-based PaymentError handler
import ChooseSubscriptionComponent from "@src/components/payment/ChooseSubscription";
import { RouteProp } from "@react-navigation/native";

type ChoosePackageProps = {
  navigation: StackNavigationProp<
    PaymentStackParamList,
    typeof PaymentRoutes.CHOOSE_SUBSCRIPTION
  >;
  appNavigator: StackNavigationProp<
    AppStackParamList,
    typeof AppRoutes.PAYMENT
  >;
  route: RouteProp<
    PaymentStackParamList,
    typeof PaymentRoutes.CHOOSE_SUBSCRIPTION
  >;
};

const ChooseSubscriptionPage: React.FC<ChoosePackageProps> = ({
  navigation,
  appNavigator,
  route,
}) => {
  const { paymentClient } = usePaymentClient();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters(); // Use IO-based loading context setters
  const { subscription_status } = route.params;

  // IO for navigating to the payment setup page
  const navigateToPaymentSetupIo = useCallback(
    (paymentIntentSecret: StripeSetupIntentSecret): IO.IO<void> =>
      () =>
        navigation.navigate(PaymentRoutes.PAYMENT_SETUP, {
          setup_intent_secret: paymentIntentSecret,
        }),
    [navigation]
  );
  const navigateToDemographicSurveyIo = useCallback(
    (subscription_status: SubscriptionStatus): IO.IO<void> =>
      () =>
        navigation.navigate(PaymentRoutes.DEMOGRAPHIC_SURVEY, {
          subscription_status,
        }), // Use SubscriptionStatus here
    [navigation]
  );

  // Task for handling success state and navigating to payment setup page
  const handlePaymentSuccessTask = useCallback(
    (paymentIntentSecret: StripeSetupIntentSecret): T.Task<void> =>
      pipe(
        T.fromIO(triggerSuccessState), // Set success state
        T.flatMap(() => T.fromIO(navigateToPaymentSetupIo(paymentIntentSecret))) // Navigate to payment setup page
      ),
    [triggerSuccessState, navigateToPaymentSetupIo]
  );

  const handlePromoSuccessTask = useCallback(
    (): T.Task<void> =>
      pipe(
        T.fromIO(triggerSuccessState), // Set success state
        T.flatMap(() =>
          T.fromIO(navigateToDemographicSurveyIo(subscription_status))
        ) // Navigate to demographic survey page
      ),
    [triggerSuccessState, navigateToDemographicSurveyIo, subscription_status]
  );

  // Task to handle subscription package selection
  const moveToConfigurePayment = useCallback(
    (subscription: SubscriptionPlan): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState), // Start loading when selecting a package
        T.flatMap(() =>
          pipe(
            paymentClient.setupIntent(subscription),
            TE.fold(
              (error) =>
                T.fromIO(handlePaymentErrorIo(error, triggerErrorState)), // Handle payment error with IO
              (paymentIntentSecret) =>
                handlePaymentSuccessTask(
                  paymentIntentSecret as StripeSetupIntentSecret
                ) // Handle payment success
            )
          )
        )
      ),
    [
      triggerLoadingState,
      paymentClient,
      triggerErrorState,
      handlePaymentSuccessTask,
    ]
  );

  // Updated handlePromoCode function
  const handlePromoCode = useCallback(
    (promoCode: string): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            paymentClient.setPromoCode(promoCode),
            TE.fold(
              (error) => {
                const paymentError: PaymentError = {
                  type: "UnknownError",
                  message: error.message || "Unknown payment error",
                };
                return T.fromIO(
                  handlePaymentErrorIo(paymentError, triggerErrorState)
                );
              },
              (response: PromoCodeResponse) => {
                const { status } = response;

                switch (status) {
                  case "Valid":
                    return handlePromoSuccessTask();
                  case "Invalid":
                    const invalidError: PaymentError = {
                      type: "ValidationError",
                      message: "Invalid promo code",
                    };
                    return T.fromIO(
                      handlePaymentErrorIo(invalidError, triggerErrorState)
                    );
                  case "Expired":
                    const expiredError: PaymentError = {
                      type: "ValidationError",
                      message: "Promo code expired",
                    };
                    return T.fromIO(
                      handlePaymentErrorIo(expiredError, triggerErrorState)
                    );
                  default:
                    const unknownError: PaymentError = {
                      type: "UnknownError",
                      message: "Unknown promo code status",
                    };
                    return T.fromIO(
                      handlePaymentErrorIo(unknownError, triggerErrorState)
                    );
                }
              }
            )
          )
        )
      ),
    [paymentClient, triggerErrorState, handlePromoSuccessTask]
  );

  return (
    <ChooseSubscriptionComponent
      onPackageSelectionTask={moveToConfigurePayment}
      subscriptionStatus={subscription_status as SubscriptionStatus}
      setPromoCode={handlePromoCode}
    />
  );
};

export default ChooseSubscriptionPage;
