// @navigation/navigators/Payment.navigator.tsx
import React from "react";
import {
    createStackNavigator,
    StackNavigationProp,
} from "@react-navigation/stack";
import { AppStackParamList, PaymentStackParamList } from "@navigation/NavigationRouteParameters";
import ChooseSubscriptionPage from "@pages/payment/ChooseSubscription.page";
import PaymentSetupPage from "@pages/payment/PaymentSetup.page";
import { AppRoutes, PaymentRoutes } from "@navigation/RouteEnums";
import { PaymentClientProvider } from "@contexts/PaymentClient.context";
import withAppNavigator from "@navigation/helpers/withAppNavigator";
import { View } from "react-native";
import DemoGraphicSurvey from "@src/pages/demographic/DemographicSurvey.page";

const Stack = createStackNavigator<PaymentStackParamList>();

type PaymentNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.PAYMENT>;
};

const PaymentNavigator: React.FC<PaymentNavigatorProps> = ({ navigation }) => {
    return (
        <PaymentClientProvider>
            <Stack.Navigator
                initialRouteName={PaymentRoutes.CHOOSE_SUBSCRIPTION}
                screenOptions={{
                    headerTintColor: "#fff",
                    cardStyle: { backgroundColor: "#053A3A" },
                    cardOverlay: () => (
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: "#053A3A",
                            }}
                        />
                    ),
                    detachPreviousScreen: false,
                }}
            >
                <Stack.Screen
                    name={PaymentRoutes.CHOOSE_SUBSCRIPTION}
                    component={withAppNavigator(ChooseSubscriptionPage, navigation)}
                    options={{ headerShown: false }}
                />

                <Stack.Screen
                    name={PaymentRoutes.PAYMENT_SETUP}
                    component={withAppNavigator(PaymentSetupPage, navigation)}
                    options={{ headerShown: false }}
                />
                <Stack.Screen
                    name={PaymentRoutes.DEMOGRAPHIC_SURVEY}
                    component={withAppNavigator(DemoGraphicSurvey, navigation)} 
                    options={{ headerShown: false }}
                />
            </Stack.Navigator>
        </PaymentClientProvider>
    );
};

export default PaymentNavigator;
