// @model/clients/AccountApi.ts

import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

// Define Email type (assuming it's a simple string for now)
export const Email = t.string;
export type Email = t.TypeOf<typeof Email>;

// Define SubscriptionStatus type (mirroring the Scala service)
export const SubscriptionStatus = t.union([
    t.literal('Paid'),
    t.literal('Canceled'),
    t.literal('PaymentFailed'),
    t.literal('FreeTrialMode'),
    t.literal('PaymentNotYetConfigured'),
]);
export type SubscriptionStatus = t.TypeOf<typeof SubscriptionStatus>;

// Define Expiration type (using ISO string date)
export const Expiration = DateFromISOString;
export type Expiration = t.TypeOf<typeof Expiration>;

// Define AccountResponse type
export const AccountResponse = t.type({
    email: Email,
    status: SubscriptionStatus,
    expiration: Expiration,
});
export type AccountResponse = t.TypeOf<typeof AccountResponse>;

// Define possible error types for Account operations
export type AccountError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'AccountNotFound'; message: string }
    | { type: 'UnknownError'; message: string };
