// @contexts/AuthenticationClient.context.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { AuthenticationClient } from '@services/clients/AuthenticationClient';
import { DefaultAuthenticationClient } from '@services/ouroboros/DefaultAuthenticationClient';
import { StubAuthenticationClient } from '@services/stubs/StubAuthenticationClient';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';

interface AuthenticationClientContextType {
  authenticationClient: AuthenticationClient;
}

const AuthenticationClientContext = createContext<AuthenticationClientContextType | undefined>(undefined);

export const AuthenticationClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Memoize the creation of AuthenticationClient, conditionally using the StubAuthenticationClient if the flag is set
  const authenticationClient = useMemo(() => {
    if (enabledFeatures[FeaturesEnum.StubClients]) {
      return new StubAuthenticationClient() as AuthenticationClient; // Use the stub implementation
    } else {
      return new DefaultAuthenticationClient() as AuthenticationClient; // Use the default implementation
    }
  }, []);

  const contextValue = useMemo(
      () => ({
        authenticationClient,
      }),
      [authenticationClient]
  );

  return (
      <AuthenticationClientContext.Provider value={contextValue}>
        {children}
      </AuthenticationClientContext.Provider>
  );
};

export const useAuthenticationClient = (): AuthenticationClientContextType => {
  const context = useContext(AuthenticationClientContext);
  if (!context) {
    throw new Error('useAuthenticationClient must be used within an AuthenticationClientProvider');
  }
  return context;
};
