// @model/clients/FeedbackApi.ts

import * as t from "io-ts";

// Define feedback-related models (mirroring the Scala service)
export const FeedbackContents = t.string;
export type FeedbackContents = t.TypeOf<typeof FeedbackContents>;

export const FeedbackType = t.union([
    t.literal("Bug"),
    t.literal("FeatureRequest"),
    t.literal("General"),
]);
export type FeedbackType = t.TypeOf<typeof FeedbackType>;

// Define request and response types

// Request to submit feedback
export const SubmitFeedbackRequest = t.type({
    feedback: FeedbackContents,
    type: FeedbackType,
});
export type SubmitFeedbackRequest = t.TypeOf<typeof SubmitFeedbackRequest>;

// Response for successfully submitting feedback
export const SubmitFeedbackResponse = t.type({
    feedback_id: t.string, // assuming feedback ID is a string
});
export type SubmitFeedbackResponse = t.TypeOf<typeof SubmitFeedbackResponse>;


// Define FeedbackError type
export type FeedbackError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'FeedbackNotFound'; feedbackId: string }
    | { type: 'UnknownError'; message: string };
