// @styles/Feedback.style.ts
import { StyleSheet } from 'react-native';

export const FeedbackStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'left',
    },
    headertext: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'left',
    },
    subheader: {
        fontSize: 20,
        fontWeight: '600',
        marginBottom: 8,
    },
    sectionContainer: {
        padding: 10,
        backgroundColor: '#fff',
        color: 'gray',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        minHeight: 100,
        textAlignVertical: 'top',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        marginBottom: 16,
        elevation: 2,
    },
    section: {
        fontSize: 16,
        lineHeight: 24,
    },
    input: {
        width: '100%',
        borderColor: '#ccc',
        color: 'black',
        borderWidth: 1,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
        textAlignVertical: 'center', // Ensure multiline text is top-aligned
        backgroundColor: '#f9f9f9',  // Slight background color for contrast
    },
    input2: {
        width: '100%',
        color: 'black',
        borderWidth: 1,
        borderColor: 'transparent',
        borderTopWidth: 10,
        borderBottomWidth: 10,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
        textAlignVertical: 'center', // Ensure multiline text is top-aligned
        backgroundColor: '#f9f9f9',  // Slight background color for contrast
    },
    categoryPicker: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Adjust to spread the buttons evenly
        marginBottom: 16,
    },
    categoryButton: {
        flex: 1,
        marginHorizontal: 5,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    modalContent: {
        width: 300,
        padding: 24,
        backgroundColor: '#fff',
        borderRadius: 4,
        alignItems: 'center',
        elevation: 5, // Add some shadow for depth (Android)
        shadowColor: '#000', // Add some shadow for depth (iOS)
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 3,
    },
    successMessage: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 16,
    },
    button: {
        paddingVertical: 5.5,
        paddingHorizontal: 20,
        backgroundColor: '#296a6a',
        borderRadius: 5,
        marginLeft: 2
    },
    disabledButton: {
        paddingVertical: 8,
        paddingHorizontal: 15,
        paddingBottom: 15,
        backgroundColor: 'gray',
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: .75
    },
    dropdownText: {
        color: '#000',
        fontSize: 16,
        fontWeight: 'light',
        textAlign: 'center',
        padding: 8,
    },
    gridContainer: {
        flex: 1,
        padding: 10,
        width: '100%',
      },
    gridRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    gridItem: {
        flex: 1,
        margin: 5,
    },
});

export default FeedbackStyles;
