// @contexts/Loading.context.tsx
import React, { createContext, useContext, ReactNode, useCallback, useMemo } from 'react';
import { RequestState } from '@model/LoadingContext';
import * as IO from 'fp-ts/lib/IO';
import { useIoState } from '@utils/useIoState';
import { pipe } from 'fp-ts/lib/function';

// Contexts
const LoadingStateContext = createContext<RequestState | undefined>(undefined);
const LoadingErrorContext = createContext<{ errorMessage: string; additionalInfo?: string } | undefined>(undefined);
const LoadingSettersContext = createContext<IoLoadingSetters | undefined>(undefined);

export type IoLoadingSetters = {
    triggerLoadingState: IO.IO<void>;
    triggerSuccessState: IO.IO<void>;
    triggerIdleState: IO.IO<void>;
    triggerErrorState: (message: string, info?: string) => IO.IO<void>;
};

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setStateIo] = useIoState<RequestState>('idle');
    const [errorMessage, setErrorMessageIo] = useIoState<string>('');
    const [additionalInfo, setAdditionalInfoIo] = useIoState<string | undefined>(undefined);

    // Memoize IO-based setters
    const triggerLoadingState = useMemo(() => setStateIo('loading'), [setStateIo]);
    const triggerSuccessState = useMemo(() => setStateIo('success'), [setStateIo]);
    const triggerIdleState = useMemo(() => setStateIo('idle'), [setStateIo]);
    const triggerErrorState = useCallback(
        (message: string, info?: string): IO.IO<void> =>
            pipe(
                setErrorMessageIo(message),
                IO.chain(() => setAdditionalInfoIo(info)),
                IO.chain(() => setStateIo('error'))
            ),
        [setErrorMessageIo, setAdditionalInfoIo, setStateIo]
    );

    // Memoize the setters object
    const settersValue = useMemo(
        () => ({
            triggerLoadingState,
            triggerSuccessState,
            triggerIdleState,
            triggerErrorState,
        }),
        [triggerLoadingState, triggerSuccessState, triggerIdleState, triggerErrorState]
    );

    return (
        <LoadingStateContext.Provider value={state}>
            <LoadingErrorContext.Provider value={{ errorMessage, additionalInfo }}>
                <LoadingSettersContext.Provider value={settersValue}>
                    {children}
                </LoadingSettersContext.Provider>
            </LoadingErrorContext.Provider>
        </LoadingStateContext.Provider>
    );
};

// Hook to access loading state
export const useLoadingState = (): RequestState => {
    const context = useContext(LoadingStateContext);
    if (context === undefined) {
        throw new Error('useLoadingState must be used within a LoadingProvider');
    }
    return context;
};

// Hook to access loading error
export const useLoadingError = (): { errorMessage: string; additionalInfo?: string } => {
    const context = useContext(LoadingErrorContext);
    if (context === undefined) {
        throw new Error('useLoadingError must be used within a LoadingProvider');
    }
    return context;
};

// Hook to access IO-based loading setters
export const useIoBasedLoadingSetters = (): IoLoadingSetters => {
    const context = useContext(LoadingSettersContext);
    if (context === undefined) {
        throw new Error('useIoBasedLoadingSetters must be used within a LoadingProvider');
    }
    return context;
};
