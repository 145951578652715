// @styles/Workspace.style.ts
import { StyleSheet } from 'react-native';

export const WorkspaceStyles = StyleSheet.create({
    headerStyle: {
        backgroundColor: "#296a6a",
    },
    headerTitleStyle: {
        color: "#fff",
    },
    drawerItem: {
        backgroundColor: 'transparent',
    },
    activeDrawerItem: {
        backgroundColor: '#005757',
    },
    labelStyle: {
        color: '#fff',
    },
    drawerStyle: {
        backgroundColor: "#053A3A",
    },
});
