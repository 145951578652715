// @pages/workspace/DocumentSelection.page.tsx

import React, { useEffect, useCallback, useMemo } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  WorkspaceRoutes,
  AppRoutes,
  DocumentRoutes,
  AuthenticationRoutes,
} from "@navigation/RouteEnums";
import { WorkspaceRoutesParamList, AppStackParamList } from "@navigation/NavigationRouteParameters";
import { DocumentID, UserDocument } from "@model/documents/core";
import DocumentSelectionComponent, {
  DocumentSelectionComponentProps,
} from "@src/components/workspace/DocumentSelection";
import { useLifecycleClient } from "@contexts/LifecycleClient.context";
import { useDocumentContext } from "@contexts/Document.context";
import { useDocumentListContext } from "@contexts/DocumentList.context";
import { useAuthenticationClient } from "@contexts/AuthenticationClient.context";
import { pipe } from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import * as IO from "fp-ts/lib/IO";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context";
import { handleLifecycleErrorIo } from "@utils/errorHandlers/lifecycleErrorHandler";
import { handleAuthenticationErrorIo } from "@utils/errorHandlers/errorHandlers";
import { AuthenticationError } from "@model/clients/AuthenticationApi";
import { LifecycleError } from "@model/clients/LifecycleApi";
import { usePaymentClient } from "@contexts/PaymentClient.context";
import { PaymentError } from "@model/clients/PaymentApi";
import { handlePaymentErrorIo } from "@utils/errorHandlers/paymentErrorHandler";

type DocumentSelectionProps = {
  navigation: StackNavigationProp<
    WorkspaceRoutesParamList,
    typeof WorkspaceRoutes.DOCUMENT_SELECTION
  >;
  appNavigator: StackNavigationProp<
    AppStackParamList,
    typeof AppRoutes.WORKSPACE
  >;
};

const DocumentSelectionPage: React.FC<DocumentSelectionProps> = ({
  appNavigator,
  navigation,
}) => {
  const { availableDocuments, fetchDocuments } = useDocumentListContext();
  const { lifecycleClient } = useLifecycleClient();
  const { setDocumentIo } = useDocumentContext();
  const { authenticationClient } = useAuthenticationClient();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters();
  const { paymentClient } = usePaymentClient();

  useEffect(() => {
    void fetchDocuments(); // Fetch the documents when the component mounts
  }, [fetchDocuments]);

  // IO to navigate to review page
  const navigateToReviewPageIo = useCallback(
    (document: UserDocument): IO.IO<void> =>
      pipe(
        setDocumentIo(document),
        IO.flatMap(() => triggerSuccessState),
        IO.flatMap(
          () => () =>
            appNavigator.navigate(AppRoutes.DOCUMENT, {
              screen: DocumentRoutes.REVIEW,
            })
        )
      ),
    [setDocumentIo, triggerSuccessState, appNavigator]
  );

  // IO to navigate to feedback submission page
  const navigateToFeedbackSubmission: IO.IO<void> = () =>
    navigation.navigate(WorkspaceRoutes.FEEDBACK_SUBMISSION);



  // Handle selecting a document
  const handleSelectDocument = useCallback(
    (documentID: DocumentID): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            lifecycleClient.fetchDocument(documentID),
            TE.fold(
              (error: LifecycleError) =>
                T.fromIO(handleLifecycleErrorIo(error, triggerErrorState)),
              (document: UserDocument) =>
                T.fromIO(navigateToReviewPageIo(document))
            )
          )
        )
      ),
    [
      triggerLoadingState,
      lifecycleClient,
      triggerErrorState,
      navigateToReviewPageIo,
    ]
  );

  // Navigate to document creation
  const handleCreateDocument = useCallback(
    T.fromIO(() => navigation.navigate(WorkspaceRoutes.DOCUMENT_CREATION)),
    [navigation]
  );

  // Handle document download
  const triggerUserDownload = useCallback(
    (blob: Blob, title: string): IO.IO<void> =>
      () => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.md`; // Adjust the filename and extension as needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
    []
  );

  const handleDownload = useCallback(
    (id: DocumentID, title: string): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            lifecycleClient.downloadDocumentAsBlob(id),
            TE.fold(
              (error: LifecycleError) =>
                T.fromIO(handleLifecycleErrorIo(error, triggerErrorState)),
              (blob) =>
                pipe(
                  T.fromIO(triggerUserDownload(blob, title)),
                  T.flatMap(() => T.fromIO(triggerSuccessState))
                )
            )
          )
        )
      ),
    [
      triggerLoadingState,
      lifecycleClient,
      handleLifecycleErrorIo,
      triggerSuccessState,
      triggerErrorState,
      triggerUserDownload,
    ]
  );

  // Handle document deletion
  const handleDelete = useCallback(
    (id: DocumentID): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            lifecycleClient.deleteDocument(id),
            TE.fold(
              (error: LifecycleError) =>
                T.fromIO(handleLifecycleErrorIo(error, triggerErrorState)),
              () => fetchDocuments // Refresh documents after deletion
            )
          )
        )
      ),
    [
      triggerLoadingState,
      lifecycleClient,
      handleLifecycleErrorIo,
      triggerErrorState,
      fetchDocuments,
    ]
  );

  // Memoize the props passed to DocumentSelectionComponent
  const documentSelectionProps: DocumentSelectionComponentProps = useMemo(
    () => ({
      onCreateDocumentTask: handleCreateDocument,
      onSelectDocumentTask: handleSelectDocument,
      availableDocuments,
      handleDownload,
      handleDelete,
    }),
    [
      handleCreateDocument,
      handleSelectDocument,
      availableDocuments,
      handleDownload,
      handleDelete,
    ]
  );

  return <DocumentSelectionComponent {...documentSelectionProps} />;
};

export default DocumentSelectionPage;
