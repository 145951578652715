// @styles/DocumentSelection.style
import { none } from "fp-ts/lib/Option";
import { StyleSheet } from "react-native";

export const DocumentSelectionStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#fff",
    borderColor: "transparent",
  },
  categoryContainer: {
    marginBottom: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  categoryHeading: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 12,
    color: "#333",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    width: 300,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  modalMessage: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: "center",
  },
  modalActions: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#296a6a",
    width: 120,
    alignItems: "center",
  },
  modalButtonText: {
    color: "#fff",
    fontSize: 16,
  },
  dropdownText: {
    marginLeft: 8,
    fontSize: 16,
    color: '#000',
  },
  radioButton: {
    width: 20,
    height: 20,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonSelected: {
    backgroundColor: 'white',
  },
  actionButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'static',
    top: 0,
    marginBottom: 20,
    paddingBottom: 20,
    backgroundColor: 'white',
    paddingTop: 10,
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  actionButton: {
    padding: 10,
    paddingHorizontal: 25,
    flexDirection: 'row',
    gap: 2,
    backgroundColor: '#296a6a',
    borderRadius: 5,
    alignItems: 'center'
  },
  actionButtonText: {
    color: 'white',
  },
  text: {
    fontSize: 20,
    marginBottom: 16,
  },
  documentList: {
    flex: 1,
    marginBottom: 16,
  },
  button2: {
      paddingVertical: 5,
      paddingHorizontal: 20,
      paddingBottom: 10,
      backgroundColor: '#296a6a',
      borderRadius: 5,
  },
  buttonText: {
      color: '#fff',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
  },
  documentItem: {
    width: "48%",
    marginBottom: 30,
    padding: 16,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    backgroundColor: "#296a6a",
    // zIndex: 1
  },
  gridContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  gridItem: {
    width: "48%", 
    marginBottom: 10,
  },
  listItem: {
    width: "100%", 
    marginBottom: 10,
  },
  documentName: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 8,
  },
  viewToggleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  dropdown: {
    position: "absolute",
    right: 10,
    top: 20,
    minHeight: 'auto',
    backgroundColor: "#fff",
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 2,
    elevation: 5,
    zIndex: 999,
  },
  dropdownButton: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  bottomSheet: {
    backgroundColor: 'white',
    padding: 20,
    borderTopLeftRadius: 10,
    borderBottomColor: '#296a6a',
    borderBottomWidth: 1,
    borderTopRightRadius: 10,
    flexDirection: 'row'
  },
  closeButtonMain: {
    backgroundColor: 'white',
    paddingVertical: 10,
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: '#296a6a',
    paddingHorizontal: 30,
    paddingVertical: 5,
    paddingBottom: 8,
    borderRadius: 5
  },
  closeButtonText: {
    color: '#fff',
  },
  icon: {
    marginRight: 10,
  },
  dropdownContainer: {
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textDropdown: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  documentDate: {
    color: '#fff',
    marginTop: -8,
    fontSize: 10
  },
  textContainer: {
    flexDirection: 'column',
    gap: 0,
  }
});
