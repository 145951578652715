// @contexts/Document.context.tsx

import React, { createContext, useContext, ReactNode, useMemo, useCallback } from 'react';
import { UserDocument, Section, SectionID, DocumentID } from '@model/documents/core';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities
import { useIoState } from '@utils/useIoState';
import { pipe } from 'fp-ts/lib/function';

type DocumentContextType = {
    document: UserDocument | null;
    activeSectionID: SectionID | null;
    setDocumentIo: (doc: UserDocument) => IO.IO<void>; // Replaced with IO
    setActiveSectionIDIo: (id: SectionID) => IO.IO<void>; // Replaced with IO
    updateSectionIo: (updatedSection: Section) => IO.IO<void>; // Replaced with IO
};

type NonNullDocumentContextType = {
    document: UserDocument;
    activeSectionID: SectionID | null;
    setDocumentIo: (doc: UserDocument) => IO.IO<void>; // Replaced with IO
    setActiveSectionIDIo: (id: SectionID) => IO.IO<void>; // Replaced with IO
    updateSectionIo: (updatedSection: Section) => IO.IO<void>; // Replaced with IO
};

const DocumentContext = createContext<DocumentContextType | undefined>(undefined);

type DocumentProviderProps = {
    children: ReactNode;
};

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
    const [document, setDocumentIo] = useIoState<UserDocument | null>(null); // Replaced with IO
    const [activeSectionID, setActiveSectionIDIo] = useIoState<SectionID | null>(null); // Replaced with IO

    // Update section with IO
    const updateSectionIo = useCallback(
        (updatedSection: Section): IO.IO<void> => {
            if (!document) return () => undefined;

            const updatedContents = document.document_contents.map((section) =>
                section.metadata.index === updatedSection.metadata.index
                    ? updatedSection
                    : section
            );

            const newDocument = { ...document, document_contents: updatedContents };

            return setDocumentIo(newDocument);
        },
        [document, setDocumentIo]
    );

    // Memoize the context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            document,
            activeSectionID,
            setDocumentIo,
            setActiveSectionIDIo,
            updateSectionIo,
        }),
        [document, activeSectionID, setDocumentIo, setActiveSectionIDIo, updateSectionIo]
    );

    return <DocumentContext.Provider value={contextValue}>{children}</DocumentContext.Provider>;
};

export const useDocumentContext = (): DocumentContextType => {
    const context = useContext(DocumentContext);
    if (!context) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};

// This hook guarantees that the document is non-null
export const useNonNullDocumentContext = (): NonNullDocumentContextType => {
    const context = useDocumentContext();
    if (!context.document) {
        throw new Error('Document is not set in DocumentContext');
    }

    return {
        ...context,
        document: context.document as UserDocument, // Assert that document is non-null
    };
};

// Helper hook to get the document ID safely
export const useDocumentID = (): DocumentID => {
    const { document } = useNonNullDocumentContext();
    return document.id;
};
