import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Animated,
  Modal,
} from "react-native";
import { DocumentID, DocumentMetadata } from "@model/documents/core";
import { DocumentSelectionStyles as styles } from "@styles/DocumentSelection.style";
import { Task } from "fp-ts/Task";
import * as IO from "fp-ts/IO";
import { MaterialIcons } from "@expo/vector-icons";

export type DocumentSelectionComponentProps = {
  onCreateDocumentTask: Task<void>;
  onSelectDocumentTask: (documentID: string) => Task<void>;
  availableDocuments: DocumentMetadata[];
  handleDownload: (id: DocumentID, title: string) => Task<void>;
  handleDelete: (id: DocumentID) => Task<void>;
};

interface AnimatedDocumentItemProps {
  doc: DocumentMetadata;
  isGridView: boolean;
  onSelect: IO.IO<void>;
  selected: boolean;
}

const AnimatedDocumentItem: React.FC<AnimatedDocumentItemProps> = ({
  doc,
  isGridView,
  onSelect,
  selected,
}) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  // last_edited date for each renderItem
  const formattedDate = new Date(doc.last_edited).toLocaleDateString();

  return (
    <Animated.View
      style={[
        styles.documentItem,
        isGridView ? styles.gridItem : styles.listItem,
        { opacity: fadeAnim },
        { backgroundColor: "#296a6a" }, // Change color based on selected
      ]}
    >
      <TouchableOpacity style={styles.dropdownContainer} onPress={onSelect}>
        <View
          style={[styles.radioButton, selected && styles.radioButtonSelected]}
        />
        <View style={styles.textContainer}>
          <Text style={styles.documentName}>{doc.name}</Text>
          <Text style={styles.documentDate}>{`Last Edited: ${formattedDate}`}</Text>
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
};

// Categorizing the documents based on the last_edited field
const categorizeDocuments = (documents: DocumentMetadata[]) => {
  const now = new Date();

  // Helper function to calculate days between two dates
  const daysAgo = (date: string) => {
    const docDate = new Date(date);
    const timeDifference = now.getTime() - docDate.getTime();
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  };

  return useMemo(() => {
    const editedWithin7Days = documents.filter(
      (doc) => daysAgo(doc.last_edited) <= 7
    );
    const editedWithin30Days = documents.filter(
      (doc) => daysAgo(doc.last_edited) > 7 && daysAgo(doc.last_edited) <= 30
    );
    const editedBefore30Days = documents.filter(
      (doc) => daysAgo(doc.last_edited) > 30
    );

    return { editedWithin7Days, editedWithin30Days, editedBefore30Days };
  }, [documents]);
};

const DocumentSelectionComponent: React.FC<DocumentSelectionComponentProps> = ({
  onCreateDocumentTask,
  onSelectDocumentTask,
  availableDocuments,
  handleDownload,
  handleDelete,
}) => {
  const [isGridView, setIsGridView] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  // Categorize documents based on their last_edited field
  const categorizedDocuments = categorizeDocuments(availableDocuments);
  
  const toggleDocumentSelection = (id: string) => {
    if (selectedDocumentId === id) {
      setSelectedDocumentId(null);
      setShowActionButtons(false); // Hide buttons if the same document is deselected
    } else {
      setSelectedDocumentId(id);
      setShowActionButtons(true); // Show buttons if a document is selected
    }
  };

  const renderDocumentItem = (doc: DocumentMetadata) => (
    <AnimatedDocumentItem
      key={doc.id}
      doc={doc}
      isGridView={isGridView}
      onSelect={() => toggleDocumentSelection(doc.id)}
      selected={selectedDocumentId === doc.id}
    />
  );

  // Handle delete confirmation
  const handleDeleteConfirmation = () => {
    if (selectedDocumentId) {
      setDeleteConfirmationVisible(true);
    }
  };

  // Confirm deletion
  const confirmDelete = () => {
    if (selectedDocumentId) {
      handleDelete(selectedDocumentId)();
    }
    setDeleteConfirmationVisible(false);
  };

  // Cancel deletion
  const cancelDelete = () => {
    setDeleteConfirmationVisible(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.textDropdown}>
        <Text style={styles.text}>Document Selection Page</Text>
        <View style={styles.viewToggleContainer}>
          <TouchableOpacity onPress={() => setIsGridView(!isGridView)}>
            <MaterialIcons
              name={isGridView ? "list" : "grid-view"}
              size={24}
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
      </View>

      {/* Sticky Action Buttons */}
      {showActionButtons && (
        <View style={styles.actionButtonContainer}>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              if (selectedDocumentId) {
                onSelectDocumentTask(selectedDocumentId)();
              }
            }}
          >
            <MaterialIcons
              name="visibility"
              size={24}
              style={styles.icon}
              color={"#fff"}
            />
            <Text style={styles.actionButtonText}>View</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              if (selectedDocumentId) {
                handleDownload(
                  selectedDocumentId,
                  availableDocuments.find(
                    (doc) => doc.id === selectedDocumentId
                  )!.name
                )();
              }
            }}
          >
            <MaterialIcons
              name="download"
              size={24}
              style={styles.icon}
              color={"#fff"}
            />
            <Text style={styles.actionButtonText}>Download</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={handleDeleteConfirmation}
          >
            <MaterialIcons
              name="delete"
              size={24}
              style={styles.icon}
              color={"#fff"}
            />
            <Text style={styles.actionButtonText}>Delete</Text>
          </TouchableOpacity>
        </View>
      )}

      <ScrollView style={styles.documentList}>
        {/* Render "Edited within last 7 days" */}
        {categorizedDocuments.editedWithin7Days.length > 0 && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeading}>Edited within last 7 days</Text>
            <View style={isGridView ? styles.gridContainer : undefined}>
              {categorizedDocuments.editedWithin7Days.map(renderDocumentItem)}
            </View>
          </View>
        )}

        {/* Render "Edited within last 30 days" */}
        {categorizedDocuments.editedWithin30Days.length > 0 && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeading}>Edited within last 30 days</Text>
            <View style={isGridView ? styles.gridContainer : undefined}>
              {categorizedDocuments.editedWithin30Days.map(renderDocumentItem)}
            </View>
          </View>
        )}

        {/* Render "Edited before 30 days" */}
        {categorizedDocuments.editedBefore30Days.length > 0 && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeading}>Edited more than 30 days ago</Text>
            <View style={isGridView ? styles.gridContainer : undefined}>
              {categorizedDocuments.editedBefore30Days.map(renderDocumentItem)}
            </View>
          </View>
        )}
      </ScrollView>

      {/* Confirmation Modal */}
      <Modal
        visible={isDeleteConfirmationVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={cancelDelete}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Confirm Deletion</Text>
            <Text style={styles.modalMessage}>
              Are you sure you want to delete this document?
            </Text>
            <View style={styles.modalActions}>
              <TouchableOpacity onPress={cancelDelete} style={styles.modalButton}>
                <Text style={styles.modalButtonText}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={confirmDelete} style={styles.modalButton}>
                <Text style={styles.modalButtonText}>Confirm</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default DocumentSelectionComponent;