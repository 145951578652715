// @services/stubs/StubAccountClient.ts

import { AccountClient } from '@services/clients/AccountClient';
import { AccountResponse, AccountError, SubscriptionStatus } from '@model/clients/AccountApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from '@utils/delayTaskEither';

export class StubAccountClient implements AccountClient {
    getAccountDetails(): TaskEither<AccountError, AccountResponse> {
        console.log('StubAccountClient.getAccountDetails called');

        // Simulate a successful account response with a delay
        return delayTaskEither(100, right({
            email: 'user@example.com',
            status: 'Paid', // Example status, you can change it based on your needs
            expiration: new Date(), // Return a Date object instead of a string
        }));
    }
}
