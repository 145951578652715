// @navigation/navigators/Document.navigator.tsx
import React from "react";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import { AppStackParamList, DocumentRoutesParamList } from "@navigation/NavigationRouteParameters";
import ReviewPage from "@pages/document/Review.page";
import FinalEditsPage from "@pages/document/FinalEdits.page";
import PromptPage from "@pages/document/Prompt.page";
import RefinePage from "@pages/document/Refine.page";
import { WorkspaceRoutes, DocumentRoutes, AppRoutes } from "@navigation/RouteEnums";
import { View } from "react-native";
import DocumentDetailsPromptPage from "@pages/document/DocumentDetailsPrompt.page";
import { ContentClientProvider } from "@contexts/ContentClient.context";

const Stack = createStackNavigator<DocumentRoutesParamList>();

type DocumentNavigatorProps = {
  navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.DOCUMENT>;
};

const DocumentNavigator: React.FC<DocumentNavigatorProps> = () => {
  return (
    <ContentClientProvider>
      <Stack.Navigator
        initialRouteName={DocumentRoutes.REVIEW}
        screenOptions={{
          headerTintColor: "#fff",
          cardStyle: { backgroundColor: "#053A3A" },
          cardOverlay: () => (
            <View
              style={{
                flex: 1,
                backgroundColor: "#053A3A",
              }}
            />
          ),
          detachPreviousScreen: true,
        }}
      >
        <Stack.Screen
          name={DocumentRoutes.REVIEW}
          component={ReviewPage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#296a6a",
            },
            headerTitleStyle: {
              color: "#fff",
            },
          }}
        />
        <Stack.Screen
          name={DocumentRoutes.FINAL_EDITS}
          component={FinalEditsPage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#296a6a",
            },
            headerTitleStyle: {
              color: "#fff",
            },
          }}
        />
        <Stack.Screen
          name={DocumentRoutes.PROMPT}
          component={PromptPage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#296a6a",
            },
            headerTitleStyle: {
              color: "#fff",
            },
          }}
        />
        <Stack.Screen
          name={DocumentRoutes.DOCUMENT_LEVEL_PROMPT}
          component={DocumentDetailsPromptPage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#296a6a",
            },
            headerTitleStyle: {
              color: "#fff",
            },
          }}
        />
        <Stack.Screen
          name={DocumentRoutes.REFINE}
          component={RefinePage}
          options={{
            headerShown: true,
            headerStyle: {
              backgroundColor: "#296a6a",
            },
            headerTitleStyle: {
              color: "#fff",
            },
          }}
        />
      </Stack.Navigator>
    </ContentClientProvider>
  );
};

export default DocumentNavigator;
