// @services/stubs/StubContentClient.ts

import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID, Section } from '@model/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse,
    ContentError,
    AppendSectionRequest,
    AppendSectionResponse,
} from '@model/clients/ContentApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from '@utils/delayTaskEither';
import {randomUUID} from "expo-crypto"; // Assume this helper exists

const SECTION_1_PLAN = `
## Section 1 Plan

Based on the provided context, Section 1: **“A Beautiful Morning”** will focus on introducing Sammie, a cat enjoying a serene and sunlit morning. The setting is likely a peaceful outdoor environment, where Sammie is sunning herself on a sun-warmed stone. This section aims to establish a calm and pleasant tone, reflecting Sammie’s contentment and the beauty of a gentle morning.

### Potential elements to explore:
- Descriptive imagery of the morning setting and environment.
- Sammie’s physical and emotional state—content, relaxed, and peaceful.
- Subtle hints of Sammie’s personality traits through her actions or reactions.
- A moment of tranquility that may serve as a contrast to the subsequent section.

### Questions for Section 1
1. **Can you describe the setting in more detail—where is Sammie sunning herself, and what elements of the environment can be highlighted?**
2. **Are there any specific sensory details (sounds, smells, textures) that you envision in this scene?**
3. **How do you want to convey Sammie’s personality traits through her actions or interactions in this section?**
4. **Should this section hint at any forthcoming changes or transitions, or remain entirely focused on the present moment?**
5. **Are there any other characters or elements you want to introduce in this section?**
`;

export class StubContentClient implements ContentClient {
    refineSection(
        documentId: DocumentID,
        request: RefineRequest
    ): TaskEither<ContentError, RefineResponse> {
        console.log('StubContentClient.refineSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.feedback + '\n\nRefined content.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    replaceSection(
        documentId: DocumentID,
        request: ReplaceSectionRequest
    ): TaskEither<ContentError, ReplaceSectionResponse> {
        console.log('StubContentClient.replaceSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.edits,
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    answerSectionQuestions(
        documentId: DocumentID,
        request: AnswerSectionQuestionsRequest
    ): TaskEither<ContentError, AnswerSectionQuestionsResponse> {
        console.log('StubContentClient.answerSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.answers + '\n\nAnswered questions.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    generateSectionQuestions(
        documentId: DocumentID,
        request: GenerateSectionQuestionsRequest
    ): TaskEither<ContentError, GenerateSectionQuestionsResponse> {
        console.log('StubContentClient.generateSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            probing_questions: SECTION_1_PLAN,
        }));
    }

    answerDocumentQuestions(
        documentId: DocumentID,
        request: AnswerDocumentQuestionsRequest
    ): TaskEither<ContentError, void> {
        console.log('StubContentClient.answerDocumentQuestions called', documentId, request);
        return delayTaskEither(100, right(undefined));
    }

    generateDocumentQuestions(
        documentId: DocumentID
    ): TaskEither<ContentError, GenerateDocumentQuestionsResponse> {
        console.log('StubContentClient.generateDocumentQuestions called', documentId);
        return delayTaskEither(100, right({
            probing_questions: 'What is the purpose of this document?\n\nWhat are the key takeaways?',
        }));
    }

    appendSection(
        documentId: DocumentID,
        request: AppendSectionRequest
    ): TaskEither<ContentError, AppendSectionResponse> {
        console.log('StubContentClient.appendSection called', documentId, request);
        return delayTaskEither(100, right({
            new_section: {
                section_contents: null, // Initialize with null as per requirements
                metadata: {
                    id: randomUUID().toString(), // Use a mock ID for the stub
                    name: request.section_title,
                    index: 99, // Should always be the next integer, but that's hard to stub.
                    status: 'Unstarted',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }}
