// @services/stubs/StubFeedbackClient.ts

import { FeedbackClient } from '@services/clients/FeedbackClient';
import { SubmitFeedbackRequest, SubmitFeedbackResponse, FeedbackError } from '@model/clients/FeedbackApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from '@utils/delayTaskEither';

export class StubFeedbackClient implements FeedbackClient {
    submitFeedback(
        request: SubmitFeedbackRequest
    ): TaskEither<FeedbackError, SubmitFeedbackResponse> {
        console.log('StubFeedbackClient.submitFeedback called', request);

        // Simulate a successful feedback submission response with a delay
        return delayTaskEither(100, right({
            feedback_id: 'stub-feedback-id',
        }));
    }
}
