// @model/documents/core
import * as t from 'io-ts';
import {Markdown} from "@model/markdown";

// UUID Type
export const UUID = t.string;
export type UUID = t.TypeOf<typeof UUID>;

// Document ID and Section ID
export const DocumentID = UUID;
export type DocumentID = t.TypeOf<typeof DocumentID>;

export const SectionID = UUID;
export type SectionID = t.TypeOf<typeof SectionID>;

// Document Metadata
export const DocumentName = t.string;
export type DocumentName = t.TypeOf<typeof DocumentName>;

export const DocumentLastEdited = t.string; // Assuming string timestamp
export type DocumentLastEdited = t.TypeOf<typeof DocumentLastEdited>;

export const SectionStatus = t.union([
    t.literal('Active'),
    t.literal('Started'),
    t.literal('Unstarted'),
    t.literal('Error')
]);
export type DocumentStatus = t.TypeOf<typeof SectionStatus>;

export const DocumentMetadata = t.type({
    name: DocumentName,
    last_edited: DocumentLastEdited,
    id: DocumentID
});
export type DocumentMetadata = t.TypeOf<typeof DocumentMetadata>;

// Section Metadata
export const SectionName = t.string;
export type SectionName = t.TypeOf<typeof SectionName>;

export const SectionIndex = t.number;
export type SectionIndex = t.TypeOf<typeof SectionIndex>;

export const SectionMetadata = t.type({
    name: SectionName,
    index: SectionIndex,
    status: SectionStatus,
    last_edited: t.union([t.null, t.string]), // Assuming string timestamp
    id: SectionID
});
export type SectionMetadata = t.TypeOf<typeof SectionMetadata>;

// Outline Section
export const OutlineSection = SectionMetadata;
export type OutlineSection = t.TypeOf<typeof OutlineSection>;

// Document Section
export const Section = t.type({
    section_contents: t.union([t.null, Markdown]), // Optional markdown formatted string
    metadata: SectionMetadata
});
export type Section = t.TypeOf<typeof Section>

export const UserDocument = t.type({
    name: DocumentName,
    id: DocumentID,
    document_contents: t.array(Section)
});
export type UserDocument = t.TypeOf<typeof UserDocument>;
