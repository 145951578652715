// @pages/account/CreateAccount.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthenticationRoutes, AppRoutes, PaymentRoutes } from '@navigation/RouteEnums';
import { AuthenticationStackParamList, AppStackParamList } from '@navigation/NavigationRouteParameters';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { Email, Password } from '@model/clients/AuthenticationApi';
import { pipe } from 'fp-ts/lib/function';
import { fold, chain as TEChain } from 'fp-ts/lib/TaskEither'; // Chain for TaskEither
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Replacing with IO-based setters
import CreateAccountComponent from '@src/components/authentication/CreateAccount';
import { handleAuthenticationErrorIo } from '@utils/errorHandlers/errorHandlers';

type CreateAccountProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.CREATE_ACCOUNT>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

// For navigating to the subscription page after success, updated to IO
const navigateToSubscriptionIo = (
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>
): IO.IO<void> =>
    () => appNavigator.navigate(AppRoutes.PAYMENT, {
        screen: PaymentRoutes.CHOOSE_SUBSCRIPTION,
        params: { subscription_status: 'PaymentNotYetConfigured' }
    });

const CreateAccountPage: React.FC<CreateAccountProps> = ({ appNavigator }) => {
    const { authenticationClient } = useAuthenticationClient();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters(); // Use IO setters

    // IO for handling the success flow (trigger success + navigate)
    const handleSuccessIo = pipe(
        triggerSuccessState,
        IO.chain(() => navigateToSubscriptionIo(appNavigator))
    );

    // Task to create account, with named IO handlers for success and error
    const createAccountTask = (email: Email, password: Password): T.Task<void> =>
        pipe(
            T.fromIO(triggerLoadingState), // Trigger loading state
            T.chain(() => authenticationClient.createAccount({ email, password })), // Attempt to create account
            fold(
                (error) => T.fromIO(handleAuthenticationErrorIo(error, triggerErrorState)), // Handle error case with IO
                () => T.fromIO(handleSuccessIo) // Handle success case using IO
            )
        );

    return <CreateAccountComponent onCreateAccount={createAccountTask} />;
};

export default CreateAccountPage;
