// @components/LoadingOverlay.tsx

import React from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  Text,
  Button,
  TouchableOpacity,
} from "react-native";
import { Modal, Portal, useTheme } from "react-native-paper";
import {
  useLoadingState,
  useLoadingError,
  useIoBasedLoadingSetters,
} from "@contexts/Loading.context"; // Use IO-based loading setters
import { enabledFeatures, FeaturesEnum } from "@src/utils/enabledFeatures";
import OuroborosSpinnerComponent from "./OuroborosSpinner";
import { sexyStyles } from "@src/styles/OuroborosSexyTheme.style";
import * as IO from "fp-ts/lib/IO"; // Import IO utilities

const LoadingOverlay: React.FC = () => {
  const state = useLoadingState();
  const { errorMessage, additionalInfo } = useLoadingError();
  const { triggerIdleState } = useIoBasedLoadingSetters(); // IO-based setter for idle state
  const theme = useTheme();

  return (
    <Portal>
      <Modal
        visible={state === "loading" || state === "error"}
        contentContainerStyle={{ shadowColor: "transparent" }}
      >
        <View style={sexyStyles.loading}>
          {state === "loading" &&
            (enabledFeatures[FeaturesEnum.SexySpinner] ? (
              <OuroborosSpinnerComponent />
            ) : (
              <ActivityIndicator size="large" color={theme.colors.primary} />
            ))}
          {state === "error" && (
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}>{errorMessage}</Text>
              {additionalInfo && (
                <Text style={styles.additionalInfoText}>{additionalInfo}</Text>
              )}
              <TouchableOpacity
                style={styles.dismissButton}
                onPress={() => triggerIdleState()} // Directly use the IO-based setter
              >
                <Text style={styles.dismissText}>Dismiss</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
    marginHorizontal: 10,
  },
  dismissButton: {
    backgroundColor: "#296a6a",
    paddingVertical: 6,
    paddingBottom: 8,
    borderRadius: 5,
    paddingHorizontal: 20,
  },
  dismissText: {
    color: "#fff",
  },
  errorText: {
    color: "white",
    marginBottom: 10,
    textAlign: "center",
  },
  additionalInfoText: {
    color: "lightgrey",
    marginBottom: 20,
    textAlign: "center",
  },
});

export default LoadingOverlay;
