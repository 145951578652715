// @pages/document/Prompt.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/RouteEnums';
import { DocumentRoutesParamList } from '@navigation/NavigationRouteParameters';
import PromptComponent from '@src/components/document/Prompt';
import { Markdown } from '@model/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as IO from 'fp-ts/lib/IO'; // Import Task utilities
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Update to io setters
import { handleContentErrorIo } from '@utils/errorHandlers/contentErrorHandler'; // Update to io handler
import { Section } from '@model/documents/core';

type PromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.PROMPT>;
};

const PromptPage: React.FC<PromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, updateSectionIo, activeSectionID } = useNonNullDocumentContext(); // Now uses io instead of task
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown };
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters(); // Use io-based loading context setters

    // IO for navigating to the review page
    const navigateToReviewIo: T.Task<void> = T.fromIO(() => navigation.navigate(DocumentRoutes.REVIEW));

    // IO for handling success case (updating section and navigating)
    const handleSuccessIo = (updatedSection: Section): IO.IO<void> =>
        pipe(
            updateSectionIo(updatedSection), // Update section
            IO.flatMap(() => navigateToReviewIo), // Navigate to review page
            IO.flatMap(() => triggerSuccessState) // Set success after navigating
        );

    const handlePromptSubmit = (userInput: string): T.Task<void> => {
        if (document && activeSectionID) {
            return pipe(
                T.fromIO(triggerLoadingState), // Set loading state
                T.flatMap(() =>
                    pipe(
                        contentClient.answerSectionQuestions(document.id, {
                            answers: userInput,
                            section_id: activeSectionID,
                        }),
                        fold(
                            (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle error case with io
                            (response) => T.fromIO(handleSuccessIo(response.updated_section)) // Handle success with io
                        )
                    )
                )
            );
        } else {
            console.error('Document or Section ID is missing');
            return T.of(undefined); // Return no-op task
        }
    };

    return <PromptComponent probingQuestion={probingQuestion} onSubmitTask={handlePromptSubmit} />;
};

export default PromptPage;
