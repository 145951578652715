// @contexts/Jwt.context.tsx

import React, {
    createContext,
    useContext,
    ReactNode,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from 'react';

import {JwtResponse, NotFound, SubscriptionStatus} from '@model/clients/AuthenticationApi';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { TaskEither, right, left, chain as TEChain } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import { useTaskState } from '@utils/useTaskState';

// Context type with TaskEither for refreshing the token
interface JwtContextType {
    isTokenExpiring: boolean;
    refreshToken: TaskEither<AuthenticationError, void>;
    subscriptionStatus: SubscriptionStatus | NotFound
}

const JwtContext = createContext<JwtContextType | undefined>(undefined);

export const JwtProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isTokenExpiring, setIsTokenExpiringTask] = useTaskState(true);
    const [subscriptionStatus, setSubscriptionStatusTask] = useTaskState<SubscriptionStatus | NotFound>('not-found');
    const lastRefreshTimeRef = useRef<number | null>(null);
    const { authenticationClient } = useAuthenticationClient();

    // Unfailing Task to get the current time
    const getCurrentTime: T.Task<number> = T.fromIO(() => Date.now());

    // Memoize scheduleTokenRefreshTask to avoid re-creating it on each render
    const scheduleTokenRefreshTask = useCallback((): T.Task<void> => {
        const refreshInterval = 90 * 60 * 1000; // 90 minutes
        return T.fromIO(() => {
            setTimeout(() => {
                void refreshTokenTaskEither(); // Trigger token refresh after the interval
            }, refreshInterval);
        });
    }, []); // No dependencies, so it runs only once.

    // Memoize handleSuccessTriggers to avoid re-creating it on each render
    const handleSuccessTriggers = useCallback(
        (jwtStatus: JwtResponse): TaskEither<AuthenticationError, void> => {
            if (jwtStatus.status === 'unauthorized') {
                return left({ type: 'Unauthorized', message: 'Expired Authentication Token' });
            } else {
                return pipe(
                    TE.fromTask(
                        T.fromIO(() => {
                            lastRefreshTimeRef.current = Date.now(); // Record the last refresh time
                        })
                    ),
                    TE.flatMap(() => TE.fromTask(scheduleTokenRefreshTask())), // Schedule next refresh
                    TE.flatMap(() => TE.fromTask(setIsTokenExpiringTask(false))), // Set token as not expiring
                    TE.flatMap(() => TE.fromTask(setSubscriptionStatusTask(jwtStatus.subscription_status)))
                );
            }
        },
        [scheduleTokenRefreshTask, setIsTokenExpiringTask]
    );

    // Memoize handleErrorTrigger to avoid re-creating it on each render
    const handleErrorTrigger = useCallback(
        (error: AuthenticationError): TaskEither<AuthenticationError, void> => {
            return pipe(
                TE.fromTask(T.fromIO(() => console.error('Error refreshing JWT:', error.message))),
                TE.flatMap(() => TE.fromTask(setIsTokenExpiringTask(true))),
                TE.flatMap(() => left(error)) // Return left (error) in TaskEither
            );
        },
        [setIsTokenExpiringTask]
    );

    // Memoize refreshTokenTaskEither to avoid re-creating it on each render
    const refreshTokenTaskEither: TaskEither<AuthenticationError, void> = useMemo(() => {
        return pipe(
            TE.fromTask(getCurrentTime),
            TE.flatMap((currentTime) => {
                if (lastRefreshTimeRef.current && currentTime - lastRefreshTimeRef.current < 60000) {
                    console.log('Skipping token refresh; already refreshed within the last minute.');
                    return right(undefined);
                } else {
                    return pipe(
                        authenticationClient.refreshJwtToken(),
                        TE.flatMap((jwtStatusResult) =>
                            jwtStatusResult.status === 'unauthorized'
                                ? handleErrorTrigger({ type: 'Unauthorized', message: 'Unauthorized action' })
                                : handleSuccessTriggers(jwtStatusResult)
                        )
                    );
                }
            })
        );
    }, [getCurrentTime, authenticationClient, handleErrorTrigger, handleSuccessTriggers]);

    useEffect(() => {
        void refreshTokenTaskEither(); // Trigger initial refresh on mount
    }, [refreshTokenTaskEither]);

    // Memoize contextValue to avoid re-creating it on each render
    const contextValue = useMemo(
        () => ({
            isTokenExpiring,
            refreshToken: refreshTokenTaskEither,
            subscriptionStatus: subscriptionStatus
        }),
        [isTokenExpiring, refreshTokenTaskEither, subscriptionStatus]
    );

    return <JwtContext.Provider value={contextValue}>{children}</JwtContext.Provider>;
};

export const useJwt = (): JwtContextType => {
    const context = useContext(JwtContext);
    if (!context) {
        throw new Error('useJwt must be used within a JwtProvider');
    }
    return context;
};
