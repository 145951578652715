// @styles/DocumentLevelPrompt.style.ts
import { StyleSheet } from 'react-native';

export const DocumentLevelPromptStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#f5f5f5',
    },
    dropdownText: {
      marginLeft: 8,
      fontSize: 16,
      color: '#000',
    },
    dropdownButton: {
      paddingHorizontal: 20,
      paddingVertical: 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'left',
        color: '#333',
    },
    documentQuestionContainer: {
        flex: 1,
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        color: 'gray',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },
    documentQuestion: {
        fontSize: 18,
        lineHeight: 24,
        color: '#555',
    },
    button2: {
        paddingVertical: 5,
        paddingHorizontal: 20,
        paddingBottom: 10,
        backgroundColor: '#296a6a',
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    input: {
        fontSize: 16,
        padding: 10,
        backgroundColor: '#fff',
        color: 'black',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        minHeight: 100,
        textAlignVertical: 'top',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        marginBottom: 10,
        elevation: 2,
    },
    submitButton: {
        marginTop: 20,
        padding: 10,
        backgroundColor: '#4CAF50',
        borderRadius: 8,
    },
    submitButtonText: {
        fontSize: 18,
        color: '#fff',
        textAlign: 'center',
    },
});
