// @model/payments.ts
import {StripeCardNumberElement, StripeAddressElement, PaymentMethodCreateParams} from "@stripe/stripe-js";

export type BillingInfo = {
    cardElement: StripeCardNumberElement
    details: PaymentMethodCreateParams.BillingDetails
}

export const BillingInfo = (
    cardElement: StripeCardNumberElement,
    address: PaymentMethodCreateParams.BillingDetails,
): BillingInfo => ({
    cardElement,
    details: address,
});

export type CardSetupError =
    | { type: 'InitializationError'}
    | { type: 'CardElementError' }
    | { type: 'AddressElementError' }
    | { type: 'StripeConnectionError' , message: string}
