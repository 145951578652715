// @pages/account/PaymentSetup.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppRoutes, PaymentRoutes, WorkspaceRoutes } from '@navigation/RouteEnums';
import { AppStackParamList, PaymentStackParamList } from '@navigation/NavigationRouteParameters';
import { RouteProp } from '@react-navigation/native';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import Task utilities
import CreditCardInformation from '@src/components/payment/CreditCardInformation';
import { BillingInfo, CardSetupError } from '@model/payments';
import { useStripe } from '@stripe/react-stripe-js';
import { pipe } from 'fp-ts/lib/function';
import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { attemptCardSetup } from '@services/CreditCardService';

type PaymentSetupProps = {
    navigation: StackNavigationProp<PaymentStackParamList, typeof PaymentRoutes.PAYMENT_SETUP>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.PAYMENT>;
    route: RouteProp<PaymentStackParamList, typeof PaymentRoutes.PAYMENT_SETUP>;
};

const PaymentSetupPage: React.FC<PaymentSetupProps> = ({ appNavigator, route }) => {
    const { setup_intent_secret } = route.params;
    const stripe = useStripe();

    // Task to handle success and navigation
    const navigateToNextStep: T.Task<void> = T.fromIO(() =>
        appNavigator.navigate(AppRoutes.WORKSPACE, {
            screen: WorkspaceRoutes.DOCUMENT_SELECTION,
        })
    );

    // Using `TE.fromNullable` to handle `null` or `undefined` stripe instance
    const loadStripe: TE.TaskEither<CardSetupError, Stripe> =
        TE.fromNullable({ type: 'InitializationError' as const })(stripe);

    const submitBillingSetup: (billingInfo: BillingInfo) => TE.TaskEither<CardSetupError, void> =
        (billingInfo: BillingInfo): TE.TaskEither<CardSetupError, void> =>
            pipe(
                loadStripe,
                TE.flatMap((safeStripe) => attemptCardSetup(safeStripe, billingInfo, setup_intent_secret)),
                TE.flatMap(() => TE.rightTask(navigateToNextStep))
            );

    return <CreditCardInformation submitBillingSetup={submitBillingSetup} />;
};

export default PaymentSetupPage;
