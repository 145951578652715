// @components/authentication/CreateAccount.tsx

import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TextInput, Button, Card, Title, Paragraph } from 'react-native-paper';
import { gradientColors } from '@styles/Fancy.style';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import { Email, Password } from '@model/clients/AuthenticationApi';
import * as EmailValidator from 'email-validator';
import * as T from 'fp-ts/lib/Task';
import { NavigationContainerRefWithCurrent, useNavigation } from '@react-navigation/native';
import { AuthenticationStackParamList } from '@navigation/NavigationRouteParameters';
import { useFocusEffect } from '@react-navigation/native';
import { useTaskState } from '@utils/useTaskState';
import { useIoState } from '@utils/useIoState'; // Use IoState instead of useState
import { AuthenticationRoutes } from '@navigation/RouteEnums';

export type CreateAccountComponentProps = {
  onCreateAccount: (email: Email, password: Password) => T.Task<void>; // Task-based prop
};

const CreateAccountComponent: React.FC<CreateAccountComponentProps> = ({ onCreateAccount }) => {
  const [email, setEmail] = useIoState<string>(''); // Replacing useState with useIoState
  const [password, setPassword] = useIoState<string>(''); // Replacing useState with useIoState
  const [confirmPassword, setConfirmPassword] = useIoState<string>(''); // Replacing useState with useIoState
  const [emailError, setEmailErrorTask] = useTaskState<string | null>(null);
  const [passwordError, setPasswordErrorTask] = useTaskState<string | null>(null);
  const navigation: NavigationContainerRefWithCurrent<AuthenticationStackParamList> = useNavigation();

  const handleSignUp = () => {
    // Reset errors before validation
    setEmailErrorTask(null)();
    setPasswordErrorTask(null)();

    // Email validation
    if (!EmailValidator.validate(email)) {
      setEmailErrorTask('Please enter a valid email address.')();
      return;
    }

    // Password validation
    if (password !== confirmPassword) {
      setPasswordErrorTask('Passwords do not match.')();
      return;
    }

    // Execute the task with the email and password
    onCreateAccount(Email(email), Password(password))(); // Call the task
  };

  useFocusEffect(
      useCallback(() => {
        // Add the event listener when the screen is focused
        const handleKeyPress = (e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            handleSignUp(); // Trigger sign-up when Enter is pressed
          }
        };

        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the screen loses focus
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, [email, password])
  );

  return (
      <LinearGradient colors={gradientColors} style={sexyStyles.background}>
        <View style={styles.container}>
          <Card style={styles.card}>
            <Card.Content>
              <Title style={sexyStyles.title}>Sign Up</Title>

              <TextInput
                  label="Email"
                  value={email}
                  onChangeText={(text) => setEmail(text)()} // Trigger IO-based setter
                  style={sexyStyles.input}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  error={!!emailError}
              />
              {emailError && (
                  <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{emailError}</Text>
                  </View>
              )}

              <TextInput
                  label="Password"
                  value={password}
                  onChangeText={(text) => setPassword(text)()} // Trigger IO-based setter
                  style={sexyStyles.input}
                  secureTextEntry
              />

              <TextInput
                  label="Confirm Password"
                  value={confirmPassword}
                  onChangeText={(text) => setConfirmPassword(text)()} // Trigger IO-based setter
                  style={sexyStyles.input}
                  secureTextEntry
              />
              {passwordError && (
                  <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{passwordError}</Text>
                  </View>
              )}

              <Button mode="contained" onPress={handleSignUp} style={sexyStyles.button}>
                Sign Up
              </Button>
              <Paragraph style={styles.loginText}>Already have an account?</Paragraph>
              <Button
                  mode="text"
                  onPress={() => navigation.navigate(AuthenticationRoutes.LOGIN)}
                  style={sexyStyles.textButton}
              >
                <Text style={{color: '#000'}}>Login</Text>
              </Button>
            </Card.Content>
          </Card>
        </View>
      </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '85%',
    maxWidth: 550,
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  errorContainer: {
    marginBottom: 8,
    marginTop: -12
  },
  loginText: {
      marginTop: 16,
      textAlign: 'center',
  },
  errorText: {
    color: 'red',
  },
});

export default CreateAccountComponent;
