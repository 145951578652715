// @services/stubs/StubLifecycleClient.ts

import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DocumentID } from '@model/documents/core';
import {
    CreateDocumentRequest,
    CreateDocumentResponse,
    ListAvailableDocumentsResponse,
    DocumentFetchResponse,
    OutlineResponse,
    LifecycleError,
} from '@model/clients/LifecycleApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from "@utils/delayTaskEither";

const SAMMIE_STORY_PROMPT = `
## Understanding the Vision

You’ve expressed a desire to create a light-hearted slice of life story centered around a cat named Sammie. The story is outlined in three sections: **“A Beautiful Morning,”** **“Dark Clouds Arrive,”** and **“A Cozy Nook.”** Each section seems to imply a progression in Sammie’s day, potentially starting from an idyllic morning, transitioning to a period of challenge or uncertainty, and concluding in comfort or resolution.

## Clarifying Questions

1. **Can you share more about Sammie’s character traits and how they influence the narrative?**
2. **What tone or mood do you want to establish in each section, particularly when transitioning from “A Beautiful Morning” to “Dark Clouds Arrive”?**
3. **Are there any specific events or themes you envision each section exploring?**
4. **What is the primary message or takeaway you want readers to have from Sammie’s story?**
5. **Are there any other characters or elements that play a significant role in the story?**
`;

const SAMMIE_STORY_INTRO = `
Sammie stretched luxuriously, her claws extending outward as she yawned, the warmth of the sun enveloping her like a soft, invisible blanket. The garden was her kingdom, a place where every nook and cranny had been explored and deemed worthy—or not—of her regal presence. Today, the sun-warmed stone at the garden's edge was the chosen throne, its smooth surface soaking up the morning's golden rays.

The air was filled with the scent of blooming flowers, a sweet symphony that played softly in the background of Sammie's world. Birds chirped their morning songs, their melodies dancing on the gentle breeze that occasionally ruffled Sammie's fur. She blinked lazily, her emerald eyes half-closed in contentment.

Through her keen senses, Sammie perceived the subtle shifts in the environment. The stone beneath her was comfortably warm, but the shadows it cast were slowly inching closer, a silent reminder of the day’s progress. Her whiskers twitched as she considered her options, a mental map of the garden unfurling in her mind. The perfect sunny spot was a coveted prize, one that required vigilance and decisiveness to claim.

As she lay there, Sammie pondered the transient nature of her sunlit haven. The clouds in the distance were merely a whisper on the horizon, but they carried with them the promise of change. For now, though, she was content to bask in the moment, her world distilled into simple pleasures and the pursuit of comfort.

The garden was hers, and in this peaceful morning, Sammie was the undisputed ruler of her domain, aware yet unfazed by the inevitable shifts that awaited. For Sammie, the quest for the perfect lounging spot was both a daily ritual and a testament to her discerning nature.
`;

// Helper function to generate documents with dates
const generateStubDocuments = (count: number, daysApart: number) => {
    return Array.from({ length: count }, (_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - index * daysApart);
        return {
            id: `stub-document-id-${index + 1}`,
            name: `Stub Document ${index + 1}`,
            last_edited: date.toISOString(),
            status: index % 2 === 0 ? 'Active' : 'Started',
        };
    });
};

export class StubLifecycleClient implements LifecycleClient {
    createDocument(
        request: CreateDocumentRequest
    ): TaskEither<LifecycleError, CreateDocumentResponse> {
        console.log('StubLifecycleClient.createDocument called', request);
        return delayTaskEither(100, right({
            prompting_question: SAMMIE_STORY_PROMPT,
            document_id: 'stub-document-id',
        }));
    }

    fetchOutline(documentId: DocumentID): TaskEither<LifecycleError, OutlineResponse> {
        console.log('StubLifecycleClient.fetchOutline called', documentId);
        return delayTaskEither(100, right({
            outline: [
                {
                    id: 'stub-section-id-1',
                    name: 'Introduction',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
                {
                    id: 'stub-section-id-2',
                    name: 'Conclusion',
                    index: 2,
                    status: 'Unstarted',
                    last_edited: null,
                },
            ],
        }));
    }

    fetchDocument(documentId: DocumentID): TaskEither<LifecycleError, DocumentFetchResponse> {
        console.log('StubLifecycleClient.fetchDocument called', documentId);
        return delayTaskEither(100, right({
            id: documentId,
            name: 'Stub Document',
            document_contents: [
                {
                    section_contents: SAMMIE_STORY_INTRO,
                    metadata: {
                        id: 'stub-section-id-1',
                        name: 'Introduction',
                        index: 1,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    },
                },
                {
                    section_contents: null, // not started
                    metadata: {
                        id: 'stub-section-id-2',
                        name: 'Conclusion',
                        index: 2,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    },
                },
            ],
        }));
    }

    listAvailableDocuments(): TaskEither<LifecycleError, ListAvailableDocumentsResponse> {
        console.log('StubLifecycleClient.listAvailableDocuments called');
        return delayTaskEither(100, right({
            documents: generateStubDocuments(12, 5),
        }));
    }

    downloadDocumentAsBlob(documentId: DocumentID): TaskEither<LifecycleError, Blob> {
        console.log('StubLifecycleClient.downloadDocumentAsBlob called', documentId);

        const blobContent = 'This is a stub document for download.';
        const blob = new Blob([blobContent], { type: 'application/octet-stream' });

        return delayTaskEither(100, right(blob));
    }

    deleteDocument(documentId: DocumentID): TaskEither<LifecycleError, void> {
        console.log('StubLifecycleClient.deleteDocument called', documentId);
        return delayTaskEither(100, right(void 0));  // Simulate successful deletion
    }
}
