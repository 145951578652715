// @pages/workspace/AccountManagement.page.tsx

import React, { useCallback, useEffect, useMemo } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppRoutes, AuthenticationRoutes, WorkspaceRoutes } from "@navigation/RouteEnums";
import { AppStackParamList, WorkspaceRoutesParamList } from "@navigation/NavigationRouteParameters";
import { useAccountClient } from "@contexts/AccountClient.context";
import { AccountResponse, AccountError } from "@model/clients/AccountApi";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import * as IO from "fp-ts/lib/IO";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context";
import { handleAccountErrorIo } from "@utils/errorHandlers/accountErrorHandler";
import { Option, some, none } from "fp-ts/lib/Option";
import { useIoState } from "@utils/useIoState";
import { PaymentError } from "@model/clients/PaymentApi";
import { handlePaymentErrorIo } from "@utils/errorHandlers/paymentErrorHandler";
import { usePaymentClient } from "@contexts/PaymentClient.context";
import { AuthenticationError } from "@model/clients/AuthenticationApi";
import { handleAuthenticationErrorIo } from "@utils/errorHandlers/errorHandlers";
import { useAuthenticationClient } from "@contexts/AuthenticationClient.context";
import AccountManagementComponent from "@components/workspace/AccountManagement";
import {pipe} from "fp-ts/lib/function";

type AccountManagementProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.ACCOUNT_MANAGEMENT>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const AccountManagementPage: React.FC<AccountManagementProps> = ({
                                                                     navigation,
                                                                     appNavigator,
                                                                 }) => {
    const { accountClient } = useAccountClient();
    const { paymentClient } = usePaymentClient();
    const { authenticationClient } = useAuthenticationClient();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();

    const [accountDetails, setAccountDetails] = useIoState<Option<AccountResponse>>(none);

    const navigateToLogin: IO.IO<void> = () =>
        appNavigator.navigate(AppRoutes.AUTH, {
            screen: AuthenticationRoutes.LOGIN,
        });

    const handleLogout = useMemo(
        (): T.Task<void> =>
            pipe(
                T.fromIO(triggerLoadingState),
                T.flatMap(() =>
                    pipe(
                        authenticationClient.logout(),
                        TE.fold(
                            (error: AuthenticationError) =>
                                T.fromIO(handleAuthenticationErrorIo(error, triggerErrorState)),
                            () =>
                                T.fromIO(
                                    pipe(
                                        navigateToLogin,
                                        IO.flatMap(() => triggerSuccessState)
                                    )
                                )
                        )
                    )
                )
            ),
        [triggerLoadingState, authenticationClient, handleAuthenticationErrorIo, triggerErrorState, appNavigator]
    );

    const handleSubscriptionCancel: T.Task<void> = useCallback(
        pipe(
            T.fromIO(triggerLoadingState),
            T.flatMap(() =>
                pipe(
                    paymentClient.cancelSubscription(),
                    TE.fold(
                        (error: PaymentError) =>
                            T.fromIO(handlePaymentErrorIo(error, triggerErrorState)),
                        () =>
                            pipe(
                                T.fromIO(triggerSuccessState),
                                T.flatMap(() => handleLogout)
                            )
                    )
                )
            )
        ),
        [triggerLoadingState, paymentClient, triggerErrorState, handlePaymentErrorIo, handleLogout, triggerSuccessState]
    );

    const fetchAccountDetailsTask: T.Task<void> = pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
            pipe(
                accountClient.getAccountDetails(),
                TE.fold(
                    (error: AccountError) => T.fromIO(handleAccountErrorIo(error, triggerErrorState)),
                    (response) => T.fromIO(setAccountDetails(some(response)))
                ),
                T.flatMap(() => T.fromIO(triggerSuccessState))
            )
        )
    );

    useEffect(() => {
        void fetchAccountDetailsTask();
    }, []);

    const accountManagementProps = useMemo(
        () => ({
            accountDetails,
            onCancelSubscription: handleSubscriptionCancel,
            onLogout: handleLogout,
        }),
        [accountDetails, handleSubscriptionCancel, handleLogout]
    );

    return <AccountManagementComponent {...accountManagementProps} />;
};

export default AccountManagementPage;
