// @pages/account/Login.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthenticationRoutes, AppRoutes, WorkspaceRoutes } from '@navigation/RouteEnums';
import { AuthenticationStackParamList, AppStackParamList } from '@navigation/NavigationRouteParameters';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import CreateForgotPasswordComponent from '@src/components/authentication/ForgotPassword';
import {Email} from '@model/clients/AuthenticationApi'
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import {pipe} from "fp-ts/lib/function";

type ForgotPasswordProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.FORGOT_PASSWORD>;
};

const ForgotPasswordPage: React.FC<ForgotPasswordProps> = ({ navigation }) => {
    const { authenticationClient } = useAuthenticationClient(); // Use the account client context

    const handleForgotPasswordSubmit =   (email: Email) =>
        pipe(TE.tryCatch(
            // TODO() Implement accountClient.forgotPassword(email)
            //  await accountClient.createAccount({  password, email });
            // appNavigator.navigate(AppRoutes.WORKSPACE, {
            //     screen: WorkspaceRoutes.DOCUMENT_SELECTION,
            // });
            () => Promise.resolve()
        , (error) => console.error('Account creation failed', error)
        )
            , TE.fold(
                error => T.asUnit(T.never),
                () => T.asUnit(T.never)
            )
        )

    return <CreateForgotPasswordComponent onForgotPasswordSubmit={handleForgotPasswordSubmit}/>
};

export default ForgotPasswordPage;
