// @components/writing/review/Section.tsx
import React from "react";
import { Section as SectionType, SectionID } from "@model/documents/core";
import SectionCard from "./SectionCard";
import SectionActions from "./SectionActions";
import * as IO from "fp-ts/lib/IO";
import { View } from "react-native";

type SectionProps = {
  section: SectionType;
  active: boolean;
  handleRefine: IO.IO<void>;
  handleFinalEdits: IO.IO<void>;
  handleDraft: IO.IO<void>;
  setActiveSection: (sectionID: SectionID) => IO.IO<void>; // Task-based setter for active section
};

const Section: React.FC<SectionProps> = ({
  section,
  active,
  handleRefine,
  handleFinalEdits,
  handleDraft,
  setActiveSection,
}) => {
  return (
    <>
      <SectionCard
        section={section}
        active={active}
        onPressIo={setActiveSection(section.metadata.id)}
      />
        {active && (
          <View>
            <SectionActions
              sectionContents={section.section_contents}
              handleDraft={handleDraft}
              handleRefine={handleRefine}
              handleFinalEdits={handleFinalEdits}
            />
          </View>
        )}
    </>
  );
};

export default React.memo(Section, (prevProps, nextProps) => {
  return (
    prevProps.active === nextProps.active &&
    prevProps.section === nextProps.section
  );
});
