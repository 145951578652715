// @utils/useTaskState.ts
import { useState, useCallback } from 'react';
import * as T from 'fp-ts/lib/Task';

export function useTaskState<S>(initialState: S): [S, (s: S) => T.Task<void>] {
    const [state, setState] = useState<S>(initialState);

    // Memoize the task-based setter to avoid unnecessary re-renders
    const setTaskState = useCallback((newState: S): T.Task<void> => {
        return T.fromIO(() => setState(newState));  // setState is effectful, and we're wrapping it in IO<void>
    }, []);

    return [state, setTaskState];
}

