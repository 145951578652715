// @services/CreditCardService.ts

import {
    SetupIntentResult,
    Stripe,
    StripeElements,
    StripeCardNumberElement,
    StripeAddressElement,
    PaymentMethodCreateParams,
} from '@stripe/stripe-js';
import * as TE from 'fp-ts/lib/TaskEither';
import { BillingInfo, CardSetupError } from '@model/payments';
import { pipe } from 'fp-ts/lib/function';
import { AddressElement, CardNumberElement } from '@stripe/react-stripe-js';
import { sequenceT } from 'fp-ts/lib/Apply';

// Submitting the card
export const attemptCardSetup = (
    safeStripe: Stripe,
    billingInfo: BillingInfo,
    setup_intent_secret: string
): TE.TaskEither<CardSetupError, SetupIntentResult> =>
    TE.tryCatch(
        () =>
            safeStripe.confirmCardSetup(setup_intent_secret, {
                payment_method: {
                    card: billingInfo.cardElement,
                    billing_details: billingInfo.details,
                },
                return_url: 'https://ouroborite.ouroboros-ai.com',
            }),
        (error) => ({
            type: 'StripeConnectionError',
            message: error instanceof Error ? error.message : 'Unable to connect to Stripe',
        })
    );

// Billing Extraction

const fetchCardNumberElement = (
    safeElements: StripeElements
): TE.TaskEither<CardSetupError, StripeCardNumberElement> =>
    TE.fromNullable<CardSetupError>({ type: 'CardElementError' })(
        safeElements.getElement(CardNumberElement)
    );

const fetchAddressElement = (
    safeElements: StripeElements
): TE.TaskEither<CardSetupError, StripeAddressElement> =>
    TE.fromNullable<CardSetupError>({ type: 'AddressElementError' })(
        safeElements.getElement(AddressElement)
    );

const extractAddressFromElement = (
    addressElement: StripeAddressElement
): TE.TaskEither<CardSetupError, PaymentMethodCreateParams.BillingDetails> =>
    pipe(
        TE.tryCatch(
            () => addressElement.getValue(),
            () => ({ type: 'AddressElementError' } as CardSetupError)
        ),
        TE.map((fetchedAddress) => fetchedAddress.value)
    );

const fetchAddress = (
    safeElements: StripeElements
): TE.TaskEither<CardSetupError, PaymentMethodCreateParams.BillingDetails> =>
    pipe(
        fetchAddressElement(safeElements),
        TE.chain(extractAddressFromElement)
    );

const prepareBillingInfo = (
    safeElements: StripeElements
): TE.TaskEither<CardSetupError, BillingInfo> =>
    pipe(
        sequenceT(TE.ApplySeq)(fetchCardNumberElement(safeElements), fetchAddress(safeElements)),
        TE.map(([cardElement, address]) => BillingInfo(cardElement, address))
    );

export default prepareBillingInfo;
