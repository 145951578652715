// @pages/document/Review.page.tsx

import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { DocumentRoutes } from "@navigation/RouteEnums";
import { DocumentRoutesParamList } from "@navigation/NavigationRouteParameters";
import SectionList from "@src/components/document/review/SectionList";
import { useContentClient } from "@contexts/ContentClient.context";
import { useNonNullDocumentContext } from "@contexts/Document.context";
import { pipe } from "fp-ts/lib/function";
import { fold } from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task"; // Import Task utilities
import * as IO from "fp-ts/lib/IO"; // Import IO utilities
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // Use IO-based loading context setters
import { handleContentErrorIo } from "@utils/errorHandlers/contentErrorHandler"; // Use IO-based error handler

type ReviewProps = {
  navigation: StackNavigationProp<
    DocumentRoutesParamList,
    typeof DocumentRoutes.REVIEW
  >;
};

const ReviewPage: React.FC<ReviewProps> = ({ navigation }) => {
  const { contentClient } = useContentClient();
  const { document: userDocument, activeSectionID } =
    useNonNullDocumentContext();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters(); // Use IO-based loading context setters

  // IO for navigating to the prompt page
  const navigateToPromptIo =
    (probingQuestions: string): IO.IO<void> =>
    () =>
      navigation.navigate(DocumentRoutes.PROMPT, {
        probingQuestion: probingQuestions,
      });

  // IO for navigating to the refinement page
  const navigateToRefineIo: IO.IO<void> = () =>
    navigation.navigate(DocumentRoutes.REFINE);

  // IO for navigating to the final edits page
  const navigateToFinalEditsIo: IO.IO<void> = () =>
    navigation.navigate(DocumentRoutes.FINAL_EDITS);

  // Task to handle draft generation, using task for client and IO for navigation
  const handleDraft: T.Task<void> = pipe(
    T.fromIO(triggerLoadingState), // Start loading when generating section questions
    T.chain(() =>
      pipe(
        contentClient.generateSectionQuestions(userDocument.id, {
          section_id: activeSectionID!,
        }),
        fold(
          (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle error with IO
          (response) =>
            T.fromIO(
              pipe(
                navigateToPromptIo(response.probing_questions),
                IO.flatMap(() => triggerSuccessState)
              )
            ) // Handle success with IO
        )
      )
    )
  );

  return (
    <SectionList
      handleDraft={handleDraft}
      handleRefine={T.fromIO(navigateToRefineIo)} // Navigation is purely IO-based
      handleEdits={navigateToFinalEditsIo} // Navigation is purely IO-based
    />
  );
};

export default ReviewPage;
